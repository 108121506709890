/**
 * @file: acl-helper.ts
 * @author: eric <xuxiang@zhichetech.com>
 * @copyright: (c) 2019-2020 sichuan zhichetech co., ltd.
 */

import { Identity, ItemSourceable } from 'model';

export function $has(identity: Identity, ...rights: string[]) {
  if (!rights.length) return true;
  return identity.acl.some(x => rights.includes(x));
}

export function hasFullAccessToItemSourceable(
  identity: Identity,
  item: ItemSourceable,
): boolean {
  return Boolean(
    item.orgId &&
      ((item.storeId && identity.visibleStoreSet.has(item.storeId)) ||
        (!item.storeId && identity.userInfo.isOrgRootUser)),
  );
}

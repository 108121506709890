import classNames from 'classnames';
import React, { PureComponent, ReactNode, HTMLProps } from 'react';
import ReactDOM from 'react-dom';
import { Brand } from './Brand';

interface Props extends HTMLProps<HTMLDivElement> {
  logo: string;
  brand: string;
  fixed?: boolean;
  open?: boolean;
  children?: ReactNode;
  onToggle: () => void;
}

const DefaultGridClass = 'kt-grid kt-grid__item kt-grid--desktop kt-grid--hor-desktop';

export class Aside extends PureComponent<Props> {
  static defaultProps: Partial<Props> = {
    fixed: true,
    open: false
  };

  render() {
    const { logo, brand, fixed, open, children, onToggle, ...props } = this.props;
    return (
      <div className={classNames('kt-aside', DefaultGridClass, {
        'kt-aside--fixed': fixed,
        'kt-aside--on': open
      })} {...props}
      >
        <Brand logo={logo} brand={brand} onToggleAside={onToggle} />
        {children}
        {open && ReactDOM.createPortal(
          <div className="kt-aside-overlay" />,
          document.body
        )}
      </div>
    );
  }
}
import React from 'react';
import { Button, ButtonProps } from './Button';
import { Icon } from './Icon';

// import SearchIcon from '!@svgr/webpack!lib/metronic/assets/icons/svg/general/search.svg';

export const SearchButton = React.memo<ButtonProps>(
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  ({ children, ref, ...props }) => (
    <Button iconOnly {...props}>
      <Icon icon="flaticon-search" />
      {children}
    </Button>
  ),
);

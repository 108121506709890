import classNames from 'classnames';
import React, { HTMLProps, MouseEvent, PureComponent, ReactNode } from 'react';
import { SvgrComponent } from 'src/declarations';
import { ColorType, ResponsiveSizeTypeMap } from '../../types';
import { Button, ButtonProps } from '../Button';
import { Dropdown, DropdownButtonProps } from '../Dropdown';
import { Icon } from '../Icon';

export interface PorletHeaderProps
  extends Omit<HTMLProps<HTMLDivElement>, 'title' | 'size'> {
  title?: ReactNode;
  subTitle?: ReactNode;
  icon?: string | SvgrComponent;
  titleColor?: ColorType;
  iconColor?: ColorType;
  size?: 'small' | 'large' | 'extra-large';
  borderless?: boolean;
  onRefresh?: () => void;
}

interface ActionButtonProps extends ButtonProps {
  icon?: string | SvgrComponent;
}

interface ActionDropdownProps extends DropdownButtonProps {
  icon?: string | SvgrComponent;
}

export const ActionButton = React.memo<ActionButtonProps>(
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  ({ icon, ref, children, clean = true, size = 'small', ...props }) => {
    const iconOnly = React.Children.count(children) === 0;
    return (
      <Button iconOnly={iconOnly} size={size} clean={clean} {...props}>
        {icon && <Icon icon={icon} />}
        {children}
      </Button>
    );
  },
);

export const ActionDropdown = React.memo<ActionDropdownProps>(
  ({ icon, children, clean = true, size = 'small', ...props }) => {
    return (
      <Dropdown size={size} clean={clean} {...props}>
        {icon && <Icon icon={icon} />}
        {children as any}
      </Dropdown>
    );
  },
);

export class PortletHeader extends PureComponent<PorletHeaderProps> {
  static ActionButton = ActionButton;
  static ActionDropdown = ActionDropdown;

  render() {
    const {
      title,
      subTitle,
      icon,
      children,
      titleColor,
      iconColor,
      size,
      borderless,
      onRefresh,
      ...props
    } = this.props;

    if (!title && !icon && !children) return null;

    const sz = size && ResponsiveSizeTypeMap.get(size);

    return (
      <div
        className={classNames('kt-portlet__head', {
          [`kt-portlet__head--${sz}`]: sz,
          [`kt-portlet__head--noborder`]: borderless,
        })}
        {...props}
      >
        <div className="kt-portlet__head-label">
          {icon && (
            <span
              className={classNames('kt-portlet__head-icon', {
                [`kt-font-${iconColor}`]: iconColor,
              })}
            >
              <Icon icon={icon} />
            </span>
          )}
          {title && (
            <h3
              className={classNames('kt-portlet__head-title', {
                [`kt-font-${titleColor}`]: titleColor,
              })}
            >
              {title}
              {subTitle && <small>{subTitle}</small>}
            </h3>
          )}
          {onRefresh && (
            <a
              href="#"
              onClick={this.onRefresh}
              className="kt-portlet__head-refresh"
            >
              <i className="fa fa-sync-alt" />
            </a>
          )}
        </div>
        {children && (
          <div className="kt-portlet__head-toolbar">
            <div className="kt-portlet__head-actions">{children}</div>
          </div>
        )}
      </div>
    );
  }

  onRefresh = (e: MouseEvent) => {
    e.preventDefault();
    this.props.onRefresh && this.props.onRefresh();
  };
}

(PortletHeader as any).displayName = 'Portlet.Header';

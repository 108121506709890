import classNames from 'classnames';
import React, { HTMLProps, PureComponent } from 'react';
import { ColorType } from '../types';
import { Dropdown, DropdownButtonProps } from './Dropdown';

interface Props extends Omit<HTMLProps<HTMLUListElement>, 'title'> {
  line?: ColorType;
  bold?: boolean;
  bolder?: boolean;
}

interface ItemProps extends DropdownButtonProps {
  url?: string;
  dropodown?: boolean;
  active?: boolean;
}

const NavItem = React.memo<ItemProps>(props => {
  const { url, active, dropodown, ...rest } = props;
  if (dropodown) {
    return (
      <Dropdown
        {...rest}
        className="nav-item"
        renderCustomButton={contents => (
          <li className={classNames('nav-item', { dropodown })} {...rest}>
            <a
              href={url || '#'}
              className={classNames('nav-link', { active: active })}
            >
              {contents}
            </a>
          </li>
        )}
      />
    );
  }
  const { children, className, ...otherProps } = rest;
  return (
    <li
      className={classNames('nav-item', className, { dropodown })}
      {...otherProps}
    >
      <a
        href={url || '#'}
        className={classNames('nav-link', { active: active })}
      >
        {children as any}
      </a>
    </li>
  );
});

export class TabNav extends PureComponent<Props> {
  static Item = NavItem;

  render() {
    const { line, bold, bolder, children, className, ...props } = this.props;

    return (
      <ul
        className={classNames('nav nav-tabs', className, {
          'nav-tabs-line': line,
          [`nav-tabs-line-${line}`]: line,
          'nav-tabs-line-2x': line && bold && !bolder,
          'nav-tabs-line-3x': line && !bold && bolder,
        })}
        role="tablist"
        {...props}
      >
        {children}
      </ul>
    );
  }
}

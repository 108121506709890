import classNames from 'classnames';
import React, { HTMLProps } from 'react';
import { SvgrComponent } from 'src/declarations';

interface Props extends HTMLProps<HTMLElement> {
  icon: string | SvgrComponent;
}

export const Icon = React.memo<Props>((props: Props) => {
  const { icon, className, ...rest } = props;
  if (typeof icon === 'string') {
    return <i className={classNames(props.icon, className)} {...rest} />;
  }
  return <props.icon className={className} {...(rest as any)} />;
});

import { StorageService } from './storage.service';
import { decodeBase64Str } from 'utils';

const TOKEN_STORAGE_KEY = '.token';

/**
 * @class Token related service class.
 */
export class TokenService {
  private readonly cacheKeyBuilder: (prefix?: string) => string;

  constructor(private readonly storage: StorageService) {
    this.storage = storage;
    this.cacheKeyBuilder = prefix => `${prefix || ''}_token`;
  }

  /**
   * Get the current user's token.
   * @returns {String} token
   */
  getToken(): string {
    return this.storage.ls_get<string>(TOKEN_STORAGE_KEY) ||
      this.storage.ss_get<string>(TOKEN_STORAGE_KEY);
  }

  /**
   * Decode the token payload.
   * @param token {string} decode the base64 encoded jwt token string.
   * @returns {any} token data.
   */
  decodeTokenPayload(token: string): any {
    const encodedData = token.replace('Bearer ', '').split('.')[1];
    const data = decodeBase64Str(encodedData);
    return JSON.parse(data);
  }

  /**
   * Verify the given token against the open id.
   * @param token {string} token
   * @returns {boolean} true if valid otherwise false.
   */
  verifyTokenAndOpenId(token: string): boolean {
    try {
      this.decodeTokenPayload(token);
      return true;
    } catch (e) { /*noop*/ }
    return false;
  }

  /**
   * Get token expire time.
   * @returns {Date|Number} expire time.
   */
  getTokenExpiry(): number {
    const token = this.getToken();
    const payload = this.decodeTokenPayload(token);
    return payload.exp;
  }

  /**
   * Set the new token for the given user.
   * @param {String} token the token to set for the user.
   */
  setToken(token: string, storeWithSessionStorage?: boolean): void {
    if (storeWithSessionStorage) {
      this.storage.ss_set(TOKEN_STORAGE_KEY, token);
      this.storage.ls_remove(TOKEN_STORAGE_KEY);
    } else {
      this.storage.ls_set(TOKEN_STORAGE_KEY, token);
      this.storage.ss_remove(TOKEN_STORAGE_KEY);
    }
  }

  /**
   * Remove token.
   */
  removeToken(): void {
    this.storage.ls_remove(TOKEN_STORAGE_KEY);
    this.storage.ss_remove(TOKEN_STORAGE_KEY);
  }

  /**
   * Build the token cache key by the given nick.
   * @param {String} nick nick of the user.
   * @returns {String} the token cache key
   */
  buildTokenCacheKey(prefix?: string): string {
    return this.cacheKeyBuilder(prefix);
  }

  /**
   * Get the cached token of the given user.
   * @param {String} nick nick of the user.
   */
  getCachedToken(prefix?: string): string | null {
    const key = this.buildTokenCacheKey(prefix);
    const token = this.storage.ls_get<string>(key);
    if (token) {
      const payload = this.decodeTokenPayload(token);
      if (payload.exp && payload.exp <= Date.now()) {
        this.storage.ls_remove(key);
        return null;
      }
      return token;
    }
    return null;
  }

  /**
   * Save the given user's token to cache.
   * @param {String} prefix the cache prefix
   * @param {String} token the token to save.
   */
  saveTokenToCache(prefix: string, token: string): void {
    const key = this.buildTokenCacheKey(prefix);
    this.storage.ls_set(key, token);
  }

  /**
   * Remove the cached token.
   * @param {String} prefix the prefix of the token to remove.
   */
  removeCachedToken(prefix: string): void {
    const key = this.buildTokenCacheKey(prefix);
    this.storage.ls_remove(key);
  }
}
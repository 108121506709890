import classNames from 'classnames';
import React, { HTMLProps, PureComponent, ReactNode } from 'react';
import { SvgrComponent } from 'src/declarations';
import { Badge, BadgeProps } from './Badge';
import { Icon } from './Icon';

interface Props extends Omit<HTMLProps<HTMLUListElement>, 'title'> {
  title?: ReactNode;
  spacing?: 'md' | 'lg';
  bold?: boolean;
  v3?: boolean;
  v4?: boolean;
  subnav?: boolean;
  collapsed?: boolean;
}

interface SectionProps extends Omit<HTMLProps<HTMLLIElement>, 'title'> {
  title?: ReactNode;
  first?: boolean;
  children: ReactNode;
}

interface ItemProps extends HTMLProps<HTMLLIElement> {
  icon?: string | SvgrComponent;
  bullet?: 'line' | 'dot';
  text?: ReactNode;
  badge?: BadgeProps;
  url?: string;
  active?: boolean;
  disabled?: boolean;
}

const NavSection = React.memo<SectionProps>(props => {
  const { first, title, children } = props;
  return (
    <>
      {title && (
        <li
          className={classNames('kt-nav__section', {
            'kt-nav__section--first': first,
          })}
        >
          <span className="kt-nav__section-text">{title}</span>
        </li>
      )}
      {children}
    </>
  );
});

const NavItem = React.memo<ItemProps>(props => {
  const {
    icon,
    text,
    badge,
    url,
    href,
    active,
    disabled,
    bullet,
    children,
    ...rest
  } = props;

  let subNavElement: any = null;

  const onlyChild: any = React.Children.count(children)
    ? React.Children.only(children)
    : null;

  if (onlyChild && onlyChild.type === Nav) {
    subNavElement = onlyChild;
  }

  return (
    <li
      className={classNames('kt-nav__item', {
        'kt-nav__item--active': active,
        'kt-nav__item--disabled': disabled,
      })}
      {...rest}
    >
      <a
        href={url || href}
        className={classNames('kt-nav__link', {
          collapsed: subNavElement && !subNavElement.props.show,
        })}
      >
        {icon ? (
          <Icon icon={icon} className="kt-nav__link-icon" />
        ) : bullet ? (
          <span
            className={classNames('kt-nav__link-bullet', {
              [`kt-nav__link-bullet--${bullet}`]: bullet,
            })}
          >
            <span />
          </span>
        ) : null}
        <span className="kt-nav__link-text">{text || children}</span>
        {badge && (
          <span className="kt-nav__link-badge">
            <Badge {...badge} />
          </span>
        )}
      </a>
      {text ? children : null}
    </li>
  );
});

const NavSeparator = React.memo(() => <li className="kt-nav__separator" />);

export class Nav extends PureComponent<Props> {
  static Section = NavSection;
  static Item = NavItem;
  static Separator = NavSeparator;

  render() {
    const {
      title,
      children,
      className,
      spacing,
      v3,
      v4,
      subnav,
      collapsed,
      ...props
    } = this.props;

    return (
      <ul
        className={classNames('kt-nav', className, {
          [`kt-nav--${spacing}-space`]: spacing,
          'kt-nav__sub': subnav,
          'kt-nav--v3': v3,
          'kt-nav--v4': v4,
          show: collapsed !== true,
        })}
        {...props}
      >
        {title && <li className="kt-nav__head">{title}</li>}
        {children}
      </ul>
    );
  }
}

/**
 * @file ApiConfig
 * @author eric <xiang.xu@beego.io>
 * @copyright (c) 2019-2020 sichuan zhichetech co., ltd..
 */

export class APIConfig {
  constructor(public endPoint: string, public timeout?: number) {

  }
}

import classNames from 'classnames';
import { HTMLProps, PureComponent, Ref } from 'react';
import { ResponsiveSizeTypeMap, StateColorType } from '../types';

interface Props extends Omit<HTMLProps<HTMLInputElement>, 'size'> {
  outline?: boolean;
  icon?: boolean;
  color?: StateColorType;
  solid?: boolean;
  size?: 'large' | 'small';
  forwardRef?: Ref<HTMLInputElement>;
}

export class Switch extends PureComponent<Props> {
  render() {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { outline, icon, color, solid, size, forwardRef, ...props } =
      this.props;
    const sz = size && ResponsiveSizeTypeMap.get(size);
    return (
      <span
        className={classNames('kt-switch', {
          'kt-switch--icon': icon,
          'kt-switch--outline': outline,
          [`kt-switch--${sz}`]: sz,
          [`kt-switch--${color}`]: color,
        })}
      >
        <label>
          <input type="checkbox" ref={forwardRef} {...props} />
          <span />
        </label>
      </span>
    );
  }
}

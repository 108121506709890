import classNames from 'classnames';
import { HTMLProps, PureComponent, ReactNode } from 'react';

interface Props extends Omit<HTMLProps<HTMLDivElement>, 'size'> {
  size?: 'small' | 'large';
  vertical?: boolean;
  children?: ReactNode;
}

export class ButtonGroup extends PureComponent<Props> {
  render() {
    const { vertical, size, children, ...props } = this.props;
    return (
      <div
        className={classNames({
          'btn-group': !vertical,
          'btn-group-vertical': vertical,
          [`btn-group-sm`]: size === 'small',
          [`btn-group-lg`]: size === 'large',
        })}
        role="group"
        aria-label="..."
        {...props}
      >
        {children}
      </div>
    );
  }
}

import classNames from 'classnames';
import React, {
  CSSProperties,
  HTMLProps,
  PureComponent,
  ReactNode,
} from 'react';
import { getString } from 'shared/components';
import { ColorType } from '../types';

interface Props extends Omit<HTMLProps<HTMLInputElement>, 'label'> {
  label?: ReactNode;
  solid?: boolean;
  indeterminate?: boolean;
  color?: ColorType;
  bold?: boolean;
  tick?: boolean;
  single?: boolean;
  controlStyle?: CSSProperties;
}

export class Checkbox extends PureComponent<Props> {
  private readonly ref = React.createRef<HTMLInputElement>();

  componentDidMount() {
    if (this.ref.current) {
      this.ref.current.indeterminate = Boolean(this.props.indeterminate);
    }
  }

  componentDidUpdate(prevProps: Props) {
    if (prevProps.indeterminate !== this.props.indeterminate) {
      if (this.ref.current) {
        this.ref.current.indeterminate = Boolean(this.props.indeterminate);
      }
    }
  }

  render() {
    const {
      label,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      indeterminate,
      solid,
      disabled,
      className,
      color,
      bold,
      tick,
      single,
      style,
      controlStyle,
      ...props
    } = this.props;
    return (
      <label
        className={classNames('kt-checkbox', className, {
          'kt-checkbox--disabled': disabled,
          'kt-checkbox--bold': bold,
          'kt-checkbox--tick': tick,
          'kt-checkbox--single': single,
          [`kt-checkbox--solid`]: solid,
          [`kt-checkbox--${color}`]: color,
        })}
        style={style}
      >
        <input type="checkbox" disabled={disabled} ref={this.ref} {...props} />
        {label && typeof label === 'string' ? (
          getString(label)
        ) : (
          <>{' '.replace(/ /g, '\u00a0')}</>
        )}
        <span style={controlStyle} />
      </label>
    );
  }
}

/**
 * @file: index.ts
 * @author: eric <xuxiang@zhichetech.com>
 * @copyright: (c) 2019-2020 sichuan zhichetech co., ltd.
 */

import {
  AsyncActionCreators,
  AsyncActionCreatorsOptions,
  autoActionType,
  createAsyncActionCreators,
  createStandardAction,
} from '../base';

import {
  OBJECT_BEING_UPDATED,
  OBJECT_BEING_UPDATED_CANCEL,
  OBJECT_BEING_UPDATED_CHANGED,
  OBJECT_BEING_UPDATED_COMMIT,
  UPDATE,
  UPDATE_FAILED,
  UPDATE_SUCCESS,
} from '../constants';

import { ActionThunk, StandardAction } from 'lib/duck/interfaces';

export interface ObjectResultActionPayload<T, U = any> {
  error?: Error | null;
  result?: T | null;
  extra?: U | null;
}

export interface ObjectResultAction<T, U = any>
  extends StandardAction<ObjectResultActionPayload<T, U>> {}

export function createObjectResultAction<T, U = any>(
  type: string,
  error: Error | null | undefined,
  result?: T | null,
  extra?: U,
): ObjectResultAction<T, U> {
  return createStandardAction<ObjectResultActionPayload<T, U>>(type, {
    error,
    result,
    extra,
  });
}

// #region create async object action creatoros
export interface AsyncObjectActionCreatorsOptions<TAppState, T>
  extends AsyncActionCreatorsOptions<TAppState, T> {
  update?: (item: T, ...args: any[]) => Promise<T | undefined>;
  getObjectBeingUpdated?: (appState: TAppState) => T | null | undefined;
}

export interface AsyncObjectActionCreators<TAppState, T>
  extends AsyncActionCreators<TAppState, T, ObjectResultAction<T>> {
  update(item: T): StandardAction<T>;
  updateSuccess(item: T, result: T): ObjectResultAction<T, T>;
  updateFailed(item: T, error: Error): ObjectResultAction<T, T>;
  requestUpdate(item: T, ...args: any[]): ActionThunk<TAppState>;
  objectBeingUpdated(): StandardAction<void>;
  objectBeingUpdatedChanged(changes: Partial<T>): StandardAction<Partial<T>>;
  commitObjectBeingUpdated(): ActionThunk<TAppState>;
  cancelObjectBeingUpdated(): StandardAction<void>;
}

export function createObjectAsyncActionCreators<TAppState, T>(
  scope: string,
  options: AsyncObjectActionCreatorsOptions<TAppState, T>,
): AsyncObjectActionCreators<TAppState, T> {
  const creators = createAsyncActionCreators<
    TAppState,
    T,
    ObjectResultAction<T>
  >(scope, options);

  // update
  function update(object: T): StandardAction<T> {
    return createStandardAction(autoActionType(scope, UPDATE), object);
  }

  function updateSuccess(object: T, result: T): ObjectResultAction<T, T> {
    return createObjectResultAction(
      autoActionType(scope, UPDATE_SUCCESS),
      null,
      result,
      object,
    );
  }

  function updateFailed(object: T, error: Error): ObjectResultAction<T, T> {
    return createObjectResultAction<T, T>(
      autoActionType(scope, UPDATE_FAILED),
      error,
      null,
      object,
    );
  }

  function requestUpdate(object: T, ...args: any[]): ActionThunk<TAppState> {
    return dispatch => {
      if (!options.update) {
        throw new Error(`${scope} options.update is required. `);
      }
      dispatch(update(object));
      options.update
        .apply(null, [object, ...args])
        .then((result: T) => {
          dispatch(updateSuccess(object, result));
        })
        .catch((err: Error) => {
          dispatch(updateFailed(object, err));
        });
    };
  }

  function objectBeingUpdated(): StandardAction<void> {
    return createStandardAction(autoActionType(scope, OBJECT_BEING_UPDATED));
  }

  function objectBeingUpdatedChanged(
    changes: Partial<T>,
  ): StandardAction<Partial<T>> {
    return createStandardAction(
      autoActionType(scope, OBJECT_BEING_UPDATED_CHANGED),
      changes,
    );
  }

  function commitObjectBeingUpdated(): ActionThunk<TAppState> {
    return (dispatch, getState) => {
      if (!options.getObjectBeingUpdated) {
        throw new Error(`${scope} options.getObjectBeingUpdated is required. `);
      }
      const state = getState();
      const object = options.getObjectBeingUpdated(state);
      if (!object) {
        throw new Error(
          `${scope} options.getObjectBeingUpdated returns null. `,
        );
      }
      dispatch(
        createStandardAction(
          autoActionType(scope, OBJECT_BEING_UPDATED_COMMIT),
        ),
      );
      dispatch(requestUpdate(object));
    };
  }

  function cancelObjectBeingUpdated(): StandardAction<void> {
    return createStandardAction(
      autoActionType(scope, OBJECT_BEING_UPDATED_CANCEL),
    );
  }

  return {
    update,
    updateSuccess,
    updateFailed,
    requestUpdate,
    objectBeingUpdated,
    objectBeingUpdatedChanged,
    commitObjectBeingUpdated,
    cancelObjectBeingUpdated,
    ...creators,
  };
}

// #endregion

import React, { HTMLProps, MouseEvent, PureComponent, ReactNode } from 'react';
import { Link } from 'react-router-dom';
import { SvgrComponent } from 'src/declarations';

interface Props extends HTMLProps<HTMLUListElement> {
  items: BreadcrumbItem[];
}

export interface BreadcrumbItem {
  href?: string;
  text: ReactNode;
  icon?: string | SvgrComponent;
}

interface BreadcrumbLinkProps {
  href: string | undefined;
  text: ReactNode;
}

class BreadcrumbLink extends PureComponent<BreadcrumbLinkProps> {
  render() {
    const { href, text } = this.props;
    return (
      <Link
        to={href || '#'}
        className="kt-subheader__breadcrumbs-link"
        onClick={this.onClick}
      >
        {text}
      </Link>
    );
  }

  onClick = (e: MouseEvent<HTMLElement>) => {
    if (
      !this.props.href ||
      this.props.href === '#' ||
      this.props.href === location.pathname
    ) {
      e.preventDefault();
    }
  };
}

export class Breadcrumb extends PureComponent<Props> {
  static displayName = 'Breadcrumb';
  render() {
    const { items, ...props } = this.props;
    return (
      <ul className="kt-subheader__breadcrumbs" {...props}>
        {items.map((item, index) => (
          <React.Fragment key={index}>
            {index > 0 && (
              <span className="kt-subheader__breadcrumbs-separator" />
            )}
            <BreadcrumbLink href={item.href} text={item.text} />
          </React.Fragment>
        ))}
      </ul>
    );
  }
}

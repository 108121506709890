import classNames from 'classnames';
import React, { ChangeEvent, HTMLProps, PureComponent } from 'react';
import { Link } from 'react-router-dom';
import { BrandColorTypeSet, ButtonColorType, ColorType } from '../types';

export interface ButtonProps
  extends Omit<HTMLProps<HTMLElement>, 'label' | 'size'> {
  tag?: 'a' | 'button' | 'input';
  color?: ButtonColorType;
  label?: boolean;
  link?: boolean;
  size?: 'small' | 'large';
  fontSize?: 'small' | 'large';
  uppercased?: boolean;
  wide?: boolean;
  clean?: boolean;
  bold?: boolean;
  tall?: 'tall' | 'taller' | 'tallest';
  outline?: boolean;
  outlineHover?: boolean;
  elevated?: boolean;
  air?: boolean;
  pill?: boolean;
  square?: boolean;
  block?: boolean;
  iconOnly?: boolean;
  circle?: boolean;
  spinner?: boolean;
  spinnerColor?: ColorType;
  spinnerSize?: 'sm' | 'md' | 'lg';
  spinnerPos?: 'left' | 'center' | 'right';
  forwardedRef?: React.Ref<HTMLElement>;

  file?: boolean;
  accepts?: string[];
  onFileChange?: (e: ChangeEvent<HTMLInputElement>) => void;
}

export class Button extends PureComponent<ButtonProps> {
  static defaultProps: Partial<ButtonProps> = {
    tag: 'button',
    spinnerPos: 'left',
  };

  private readonly inputFileRef = React.createRef<HTMLInputElement>();

  render() {
    const {
      tag,
      type,
      className,
      color,
      outline,
      outlineHover,
      label,
      bold,
      clean,
      uppercased,
      size,
      fontSize,
      wide,
      block,
      link,
      tall,
      pill,
      square,
      air,
      iconOnly,
      elevated,
      style,
      spinner,
      spinnerPos,
      spinnerSize,
      spinnerColor,
      file,
      accepts,
      onFileChange,
      circle,
      forwardedRef,
      ...props
    } = this.props;
    const brandColor = BrandColorTypeSet.has(color as any) ? color : undefined;
    const cls = classNames(
      'btn',
      {
        [`btn-${color}`]: color && !outline && !label && !brandColor,
        [`btn-${brandColor}`]: brandColor && !label && !outline,
        [`btn-label-${color}`]: label && color && !brandColor && !outline,
        [`btn-label-${brandColor}`]: label && brandColor && !outline,
        [`btn-outline-${color}`]:
          color && outline && !outlineHover && !label && !brandColor,
        [`btn-outline-hover-${color}`]:
          color && outline && outlineHover && !label && !brandColor,
        [`btn-${tall}`]: tall,
        'btn-elevate': elevated && !outline && !label && !brandColor,
        'btn-elevate-air': elevated && air && !outline && !label && !brandColor,
        'btn-bold': bold,
        'btn-clean': clean,
        'btn-upper': uppercased,
        'btn-sm': size === 'small',
        'btn-lg': size === 'large',
        'btn-font-sm': fontSize === 'small',
        'btn-font-lg': fontSize === 'large',
        'btn-wide': wide,
        'btn-link': link,
        'btn-block': block,
        'btn-pill': pill,
        'btn-square': square,
        'btn-air': air && !elevated,
        'btn-icon': iconOnly,
        'btn-icon-sm': size === 'small',
        'btn-icon-lg': size === 'large',
        'btn-circle': iconOnly && circle,
        'kt-spinner': spinner && !brandColor,
        [`kt-spinner--${spinnerSize}`]: spinner && spinnerSize,
        [`kt-spinner--${spinnerColor}`]: spinnerColor,
        'kt-spinner--right': spinner && spinnerPos === 'right',
        'kt-spinner--center': spinner && (iconOnly || spinnerPos === 'center'),
      },
      className,
    );

    const contents = React.Children.toArray(this.props.children);
    let customStyle = style;
    if (file && !props.disabled) {
      if (!customStyle) customStyle = {};
      customStyle.position = 'relative';
      const fileInput = (
        <input
          type="file"
          ref={this.inputFileRef}
          accept={accepts ? accepts.join(',') : undefined}
          onChange={onFileChange}
          onClick={() => {
            this.inputFileRef.current && (this.inputFileRef.current.value = '');
          }}
          style={{
            position: 'absolute',
            width: '100%',
            height: '100%',
            opacity: 0,
            zIndex: 2,
            left: 0,
            top: 0,
            margin: 0,
          }}
        />
      );
      contents.unshift(fileInput);
    }

    const { href, children, ...rest } = props;

    if (link && href && href !== '#' && !href.startsWith('javascript:')) {
      return (
        <Link
          to={href}
          className={cls}
          style={customStyle}
          ref={forwardedRef as any}
          {...rest}
        >
          {children}
        </Link>
      );
    }

    return React.createElement(
      tag!,
      {
        className: cls,
        style: customStyle,
        ref: forwardedRef,
        type:
          tag === 'input' || (tag === 'button' && (!type || type === 'button'))
            ? 'button'
            : void 0,
        ...props,
      },
      ...contents,
    );
  }
}

import extend from 'extend';

import {
  appendQueryString,
  joinPath,
  makeError,
  resolveContentTypeHeader,
  transformFormUrlEncodedData,
} from 'lib/restful-client/APIServiceHelper';

import { APIConfig } from 'lib/restful-client/APIConfig';
import { APIRequestOptions } from 'lib/restful-client/APIRequestOptions';
import { RequestBuilder } from 'lib/restful-client/RequestBuilder';
import { CacheService } from 'lib/services/cache.service';
import { StorageService } from 'lib/services/storage.service';
import { TokenService } from 'lib/services/token.service';
import { guid } from 'utils';

const DEFAULT_API_CONFIG = {
  endPoint: '/api',
  timeout: 120000, // 2 minutes
};

export class APIService {
  apiEndPoint: string;
  private prefix: { url: string; params?: Record<string, any> };

  constructor(
    private readonly config: APIConfig,
    private readonly cache: CacheService,
    private readonly storage: StorageService,
    private readonly tokenService: TokenService,
  ) {
    this.config = config || DEFAULT_API_CONFIG;
  }

  /**
   * Create api service for specific resource w/ the given url prefix.
   * @param {String} url the url prefix
   * @param {Object} [params] url params
   * @returns {APIService} a new api service instance.
   */
  forResource(url: string, params?: Record<string, any>): APIService {
    const service = new APIService(
      this.config,
      this.cache,
      this.storage,
      this.tokenService,
    );
    service.prefix = { url, params };
    return service;
  }

  _url(path: string, args?: Record<string, any>): string {
    if (!args) args = {};
    const exclude: Record<string, any> = {};
    let url = path.replace(/:[a-z_][a-z0_9_]*/gi, m => {
      const key = m.substr(1);
      if (Object.prototype.hasOwnProperty.call(args!, key)) {
        exclude[key] = true;
        if (args![key] === void 0) {
          throw new Error(
            `could not resolve url parameter ':${key}' in url '${path}'`,
          );
        }
        return args![key];
      } else {
        return m;
      }
    });
    const query: string[] = [];
    for (const p in args) {
      if (!exclude[p] && args[p] !== null && args[p] !== void 0) {
        let value = args[p];
        if (value === null || value === void 0) {
          value = '';
        }
        query.push(
          [encodeURIComponent(p), '=', encodeURIComponent(String(value))].join(
            '',
          ),
        );
      }
    }

    if (query.length) {
      url += '?' + query.join('&');
    }
    return url;
  }

  /**
   * Build api request url.
   * @param {String} path the relative path or url.
   * @param {Object} [args] parameters to set for the `path`.
   * @param {String} userApiEndPoint user supplied api endpoint.
   * @return {String} the final url to send request for
   * @desc variables like `:param` in `path` will replaced by
   *   values given in `args`, other values will be appended to
   *   the final url as query parameters.
   */
  url(
    path: string,
    args?: Record<string, any>,
    userApiEndPoint?: string,
  ): string {
    const url = this._url(path, args);

    const endPoint =
      userApiEndPoint ||
      (typeof this.apiEndPoint === 'string'
        ? this.apiEndPoint
        : this.config.endPoint);

    const prefix =
      this.prefix && this._url(this.prefix.url, this.prefix.params);

    return joinPath(endPoint, prefix, url);
  }

  /**
   * Set the content type header for the request.
   * @param {Object} options request options
   * @param {String} contentType the content type to set.
   */
  setContentType<T>(options: APIRequestOptions<T>, contentType: string): void {
    const contentTypeHeader = resolveContentTypeHeader(options.headers);
    if (!options.headers) options.headers = {};
    options.headers[contentTypeHeader] = contentType;
  }

  /**
   * Send API call request.
   * @param {Object} options the request configuration.
   * @returns {Promise} $http response
   */
  async request<T>(options: APIRequestOptions<T>): Promise<T | undefined> {
    options = extend(
      {
        method: 'GET',
        cache: false,
        timeout: this.config.timeout,
      },
      options,
    );

    if (options.cache && !options.cacheKey) {
      options.cacheKey = `_req_${options.url}`;
    }

    if (
      /^get$/i.test(options.method) &&
      (options.useCached === true ||
        (options.useCached !== false &&
          options.cache &&
          typeof options.cache === 'number' &&
          options.cache > 0))
    ) {
      // try to load the result from cache.
      const result = this.cache.get<T>(options.cacheKey!);
      if (options.useCached === true || result !== void 0) {
        return await Promise.resolve(result);
      }
    }

    if (options.store && !options.storeKey) {
      options.storeKey = `store:${options.url}`;
    }

    let body: FormData | string | undefined = undefined;

    if (options.data) {
      // add appropriate content-type header if not present.
      if (!options.json) {
        if (options.data instanceof FormData) {
          // this.setContentType(options, 'multipart/form-data');
          body = options.data;
        } else {
          this.setContentType(options, 'application/x-www-form-urlencoded');
          body = transformFormUrlEncodedData(options.data);
        }
      } else {
        this.setContentType(options, 'application/json');
        body = JSON.stringify(options.data);
      }
    }

    if (
      /^get$/i.test(options.method) &&
      (options.cache === false ||
        (typeof options.cache === 'number' && options.cache > 0))
    ) {
      options.url = appendQueryString(options.url, '_', Date.now());
    }

    if (!options.headers) {
      options.headers = {};
    }

    const token = this.tokenService.getToken();
    if (token) {
      options.headers['Authorization'] = `Bearer ${token}`;
    }

    let clientId = this.storage.ss_get<string>('app.clientId');
    if (!clientId) {
      clientId = guid('n');
      this.storage.ss_set('app.clientId', clientId);
    }
    options.headers['X-Client-Id'] = clientId;
    options.headers['X-XHR'] = 'true';

    const appSite = this.storage.ls_get<string>('app.site');

    if (appSite) {
      options.headers['X-App-Site'] = appSite;
    }

    return await new Promise((resolve, reject) => {
      if (
        /^(post|put)$/i.test(options.method) &&
        options.data &&
        options.data instanceof FormData &&
        options.uploadProgressListener &&
        window['XMLHttpRequestUpload']
      ) {
        // use XMLHtpRequest so that we can observe the process.
        const xhr = new XMLHttpRequest();

        xhr.open(options.method, options.url);

        for (const p in options.headers) {
          if (Object.prototype.hasOwnProperty.call(options.headers, p)) {
            xhr.setRequestHeader(p, options.headers[p]);
          }
        }
        xhr.withCredentials = true;
        if (options.timeout) {
          xhr.timeout = options.timeout;
        }
        xhr.upload.addEventListener('progress', (e: ProgressEvent) => {
          try {
            options.uploadProgressListener && options.uploadProgressListener(e);
          } catch {
            /* noop */
          }
        });

        xhr.onload = () => {
          try {
            if (xhr.status === 200) {
              const data = JSON.parse(xhr.responseText);
              const result = this.handleResponseData<T>(data, options);
              resolve(result);
            } else {
              reject(new Error(xhr.statusText));
            }
          } catch (e) {
            reject(e);
          }
        };

        xhr.onerror = () => {
          reject(new Error(xhr.statusText));
        };

        xhr.ontimeout = () => {
          reject(new Error('Request timeout'));
        };

        xhr.send(options.data);

        return;
      }

      if (options.timeout) {
        setTimeout(() => {
          reject(new Error('timeout'));
        }, options.timeout);
      }

      fetch(options.url, {
        method: options.method.toUpperCase(),
        headers: options.headers,
        body,
      })
        .then(response => this.handleResponse<T>(response, options))
        .then(resolve)
        .catch(reject);
    });
  }

  /**
   * GET request.
   * @returns {RequestBuilder}
   */
  get(): RequestBuilder {
    return new RequestBuilder(this, 'GET');
  }

  /**
   * POST request.
   * @returns {RequestBuilder}
   */
  post(): RequestBuilder {
    return new RequestBuilder(this, 'POST');
  }

  /**
   * POST request.
   * @returns {RequestBuilder}
   */
  patch(): RequestBuilder {
    return new RequestBuilder(this, 'PATCH');
  }

  /**
   * PUT request.
   * @returns {RequestBuilder}
   */
  put(): RequestBuilder {
    return new RequestBuilder(this, 'PUT');
  }

  /**
   * DELETE request.
   * @returns {RequestBuilder}
   */
  delete() {
    return new RequestBuilder(this, 'DELETE');
  }

  handleResponseData<T>(data: any, options: APIRequestOptions<T>): any {
    let result: any = options.raw ? data : data.response;

    // apply result filter.
    if (options.filterFn && result && Array.isArray(result)) {
      result = result.filter(options.filterFn);
    }

    // apply result transformer.
    if (options.transformFn && result) {
      if (Array.isArray(result)) {
        for (let i = 0, len = result.length; i < len; i++) {
          const item = result[i];
          const ret = options.transformFn(item);
          if (ret !== void 0 && ret !== item) {
            result[i] = ret;
          }
        }
      } else {
        const ret = options.transformFn(result);
        if (ret !== void 0 && ret !== result) {
          result = ret;
        }
      }
    }

    if (/^get$/i.test(options.method) && options.handleNetworkResult) {
      options
        .handleNetworkResult(result)
        .then(() => {
          console.log('network result handled. ');
        })
        .catch(err => {
          console.error('error handle network result. ', err);
        });
    }

    if (
      /^get$/i.test(options.method) &&
      options.cache &&
      typeof options.cache === 'number' &&
      options.cache > 0
    ) {
      void this.cache.set(options.cacheKey!, result);
    }

    return result;
  }

  handleResponse<T>(response: Response, options: APIRequestOptions<T>): any {
    if (!response.ok) {
      return this.handleError(response, options);
    }

    if (options.responseType === 'arraybuffer') {
      return response.arrayBuffer();
    }

    if (options.responseType === 'blob') {
      return response.blob();
    }

    if (options.responseType === 'text') {
      return response.text();
    }

    return response.json().then(data => {
      return this.handleResponseData(data, options);
    });
  }

  async handleError<T>(response: Response, options: APIRequestOptions<T>) {
    const contentType = response.headers?.get('content-type') || '';

    // if the status is 0, the request is not completed.
    // https://developer.mozilla.org/en-US/docs/Web/API/XMLHttpRequest/status
    if (response.status === 0 || !contentType.startsWith('application/json')) {
      return await this.handleNetworkError(response, options);
    }

    return await response.json().then(
      result => {
        const error = makeError(result, response);
        return Promise.reject(error);
      },
      e => {
        console.error('error parse json data: ', e);
        return this.handleNetworkError(
          response,
          options,
          `Error parse json data: ${e.message}`,
        );
      },
    );
  }

  async handleNetworkError<T>(
    response: { status: number; statusText: string },
    // @ts-ignore
    _options: APIRequestOptions<T>,
    message?: string | null,
  ): Promise<T> {
    const code = response.status + ' ' + response.statusText;
    const defaultMsg =
      response.status === 0
        ? 'The network connection is unavailable for now.'
        : 'The service is temporarily unavailable: ' +
          `${response.status} (${response.statusText}). `;
    const msg = message || defaultMsg;
    const error = makeError({ code, msg }, response);
    return await Promise.reject(error);
  }
}

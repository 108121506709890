import classNames from 'classnames';
import React, { PureComponent, ReactElement, HTMLProps } from 'react';
import { ColorType, ResponsiveSizeTypeMap } from '../../types';
import { PortletHeader, PorletHeaderProps } from './PortletHeader';
import { PortletFooter } from './PortletFooter';
import { PortletBody } from './PortletBody';

import './portlet.scss';

interface Props extends HTMLProps<HTMLDivElement> {
  color?: ColorType;
  tabs?: boolean;
  last?: boolean;
  mobile?: boolean;
  responsive?: boolean;
}

export class Portlet extends PureComponent<Props> {
  static Header = PortletHeader;
  static Body = PortletBody;
  static Footer = PortletFooter;

  static defaultProps: Partial<Props> = {
    responsive: true
  };

  render() {
    const { color, tabs, last, mobile, responsive, children, ...props } = this.props;
    const childElements = React.Children.toArray(children);

    let header: ReactElement<PorletHeaderProps> | null = null;

    for (const child of childElements) {
      if (typeof child !== 'string' &&
        typeof child !== 'number' &&
        typeof (child as any).type !== 'string'
      ) {
        const name = ((child as any).type as any).displayName;
        if (name === (PortletHeader as any).displayName) {
          header = child as ReactElement;
        }
      }
    }

    const size = header?.props.size &&
      ResponsiveSizeTypeMap.get(header.props.size);

    if (color && header) {
      header.props.borderless = true;
    }

    return (
      <div className={classNames('kt-portlet', {
        'kt-portlet--mobile': mobile,
        'kt-portlet--responsive-mobile': responsive,
        'kt-portlet--last': last,
        'kt-portlet--skin-solid': color,
        'kt-portlet--tabs': tabs,
        [`kt-bg-${color}`]: color,
        [`kt-portlet--head-${size}`]: size
      })} {...props}>
        {this.props.children}
      </div>
    );
  }
}
import {
  CSSProperties,
  HTMLProps,
  ReactNode,
  useEffect,
  useState,
} from 'react';
import ReactPlaceholder from 'react-placeholder';
import 'react-placeholder/lib/reactPlaceholder.css';

interface Props extends Omit<HTMLProps<HTMLImageElement>, 'crossOrigin'> {
  onLoad?: () => void;
  onError?: () => void;
  placeholderStyle?: CSSProperties;
  errorPlaceholder?: ReactNode;
}

export function Image(props: Props) {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { src, onLoad, onError, placeholderStyle, errorPlaceholder, ...rest } =
    props;
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);

  useEffect(() => {
    const onload = () => {
      setIsLoading(false);
      props.onLoad && props.onLoad();
    };
    const onerror = () => {
      setIsLoading(false);
      setError(true);
      props.onError && props.onError();
    };

    const img = new window.Image();
    img.addEventListener('load', onload);
    img.addEventListener('error', onerror);
    img.src = src!;

    setIsLoading(true);
    setError(false);

    return () => {
      img.removeEventListener('load', onload);
      img.removeEventListener('error', onerror);
    };
  }, [props, src]);

  return (
    <ReactPlaceholder
      type="rect"
      ready={!isLoading}
      showLoadingAnimation
      style={placeholderStyle}
    >
      {error ? <div>{errorPlaceholder}</div> : <img src={src} {...rest} />}
    </ReactPlaceholder>
  );
}

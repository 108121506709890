import classNames from 'classnames';
import React, {
  FC,
  ForwardRefExoticComponent,
  HTMLProps,
  PropsWithoutRef,
  ReactNode,
  RefAttributes,
} from 'react';
import { ResponsiveSizeType, ResponsiveSizeTypeMap } from '../types';

interface Props
  extends Omit<HTMLProps<HTMLInputElement & HTMLDivElement>, 'size'> {
  size?: ResponsiveSizeType;
}

function Prepend({ children }: { children: ReactNode }) {
  return <div className="input-group-prepend">{children}</div>;
}

Prepend.displayName = 'InputGroup.Prepend';

function Append({ children }: { children: ReactNode }) {
  return <div className="input-group-append">{children}</div>;
}

Append.displayName = 'InputGroup.Append';

function Text({ children }: { children: ReactNode }) {
  return <span className="input-group-text">{children}</span>;
}

export const InputGroup: ForwardRefExoticComponent<
  PropsWithoutRef<Props> & RefAttributes<HTMLInputElement>
> & {
  Prepend: FC<{ children?: ReactNode }>;
  Append: FC<{ children?: ReactNode }>;
  Text: FC<{ children?: ReactNode }>;
} = React.forwardRef<HTMLInputElement, Props>((props, ref) => {
  const { children, size, ...rest } = props;

  const childElements = React.Children.toArray(children);

  // eslint-disable-next-line @typescript-eslint/init-declarations
  let prepend: any;
  // eslint-disable-next-line @typescript-eslint/init-declarations
  let append: any;

  for (const child of childElements) {
    if (
      typeof child !== 'string' &&
      typeof child !== 'number' &&
      typeof (child as any).type !== 'string'
    ) {
      const name = ((child as any).type as any).displayName;
      if (name === Prepend.displayName) {
        prepend = child;
      } else if (name === Append.displayName) {
        append = child;
      }
    }
  }

  const sz = size && ResponsiveSizeTypeMap.get(size);

  return (
    <div
      className={classNames('input-group', {
        [`input-group-${sz}`]: sz,
      })}
    >
      {prepend}
      <input {...rest} ref={ref} />
      {append}
    </div>
  );
}) as any;

InputGroup.Prepend = Prepend;
InputGroup.Append = Append;
InputGroup.Text = Text;

/* eslint-disable @typescript-eslint/no-unused-vars */
import ResizeSensor from 'css-element-queries/src/ResizeSensor';
import PerfectScrollbar from 'perfect-scrollbar';
import React, { HTMLProps, PureComponent, ReactNode, RefObject } from 'react';
import { KtUtil } from '../util';

type HandlerType = 'click-rail' | 'drag-thumb' | 'keyboard' | 'wheel' | 'touch';

interface Options {
  /**
   * a list of handlers to scroll the element.
   * Default: ['click-rail', 'drag-thumb', 'keyboard', 'wheel', 'touch']
   */
  handlers?: HandlerType[];

  /**
   * The scroll speed applied to mousewheel event.
   * Default: 1
   */
  wheelSpeed?: number;

  /**
   *  If this option is true, when the scroll reaches the end of the side,
   * mousewheel event will be propagated to parent element.
   * Default: false
   */
  wheelPropagation?: boolean;

  /**
   * If this option is true, swipe scrolling will be eased.
   * Default: true
   */
  swipeEasing?: boolean;

  /**
   * When set to an integer value, the thumb part of the scrollbar will not
   * shrink below that number of pixels.
   * Default: null
   */
  minScrollbarLength?: number;

  /**
   * When set to an integer value, the thumb part of the scrollbar will not
   * expand over that number of pixels.
   * Default: null
   */
  maxScrollbarLength?: number;

  /**
   * This sets threashold for ps--scrolling-x and ps--scrolling-y classes to
   * remain. In the default CSS, they make scrollbars shown regardless of
   * hover state. The unit is millisecond.
   * Default: 1000
   */
  scrollingThreshold?: number;

  /**
   * When set to true, and only one (vertical or horizontal) scrollbar is
   * visible then both vertical and horizontal scrolling will affect the scrollbar.
   * Default: false
   */
  useBothWheelAxes?: boolean;

  /**
   * When set to true, the scroll bar in X axis will not be available,
   * regardless of the content width.
   * Default: false
   */
  suppressScrollX?: boolean;

  /**
   * When set to true, the scroll bar in Y axis will not be available,
   * regardless of the content height.
   * Default: false
   */
  suppressScrollY?: boolean;

  /**
   * The number of pixels the content width can surpass the container width
   * without enabling the X axis scroll bar. Allows some "wiggle room" or
   * "offset break", so that X axis scroll bar is not enabled just because of
   * a few pixels.
   * Default: 0
   */
  scrollXMarginOffset?: number;
}

interface Props extends Omit<HTMLProps<HTMLDivElement>, 'height'> {
  options?: Options;
  children?: ReactNode;
  height?: number | (() => number);
  containerElRef?: RefObject<HTMLDivElement>;
  resetHeightOnDestroy?: boolean;
  mobileNativeScroll?: boolean;
  disableForMobile?: boolean;
  desktopNativeScroll?: boolean;
}

export class Scrollable extends PureComponent<Props> {
  static defaultProps: Props = {
    mobileNativeScroll: true,
    resetHeightOnDestroy: true,
  };

  private readonly containerElRef: RefObject<HTMLDivElement>;
  private ps: PerfectScrollbar | null | undefined;
  private rs: ResizeSensor | null | undefined;

  constructor(props: Props) {
    super(props);
    this.containerElRef =
      this.props.containerElRef || React.createRef<HTMLDivElement>();
  }

  componentDidMount() {
    this.init();
  }

  componentDidUpdate(prevProps: Props) {
    if (
      this.props.height !== prevProps.height ||
      this.props.desktopNativeScroll !== prevProps.desktopNativeScroll ||
      this.props.mobileNativeScroll !== prevProps.mobileNativeScroll ||
      this.props.disableForMobile !== prevProps.disableForMobile
    ) {
      this.update();
    }
  }

  componentWillUnmount() {
    this.destroy();
  }

  render() {
    const {
      options, // tslint:disable-line
      className,
      style,
      height,
      children,
      resetHeightOnDestroy,
      desktopNativeScroll,
      mobileNativeScroll,
      disableForMobile,
      containerElRef, // tslint:disable-line
      ...props
    } = this.props;
    const styles = Object.assign({}, { height }, style);
    return (
      <div
        ref={this.containerElRef}
        className={className}
        style={styles}
        {...props}
      >
        {children}
      </div>
    );
  }

  private readonly onContainerResize = () => {
    this.update();
  };

  private init() {
    const containerEl = this.containerElRef.current;
    if (containerEl) {
      this.rs = new ResizeSensor(document.body, this.onContainerResize);
      this.update();
    }
  }

  private update() {
    const containerEl = this.containerElRef.current;
    const height =
      typeof this.props.height === 'function'
        ? this.props.height()
        : this.props.height;

    if (!containerEl) return;

    const applyFixedHeightWithOverflow = (overflow?: string) => {
      if (!height) return;
      if (overflow) {
        containerEl.style.overflow = overflow;
      }
      containerEl.style.height = `${height}px`;
    };

    if (
      (this.props.mobileNativeScroll || this.props.disableForMobile) &&
      KtUtil.isInResponsiveRange('tablet-and-mobile')
    ) {
      if (this.ps) {
        if (this.props.resetHeightOnDestroy) {
          containerEl.style.height = 'auto';
        } else {
          applyFixedHeightWithOverflow('auto');
        }
        this.ps.destroy();
        this.ps = null;
      } else {
        applyFixedHeightWithOverflow('auto');
      }
      return;
    }

    if (this.props.desktopNativeScroll) {
      applyFixedHeightWithOverflow('auto');
      return;
    }

    applyFixedHeightWithOverflow('hidden');

    if (this.ps) {
      this.ps.update();
      return;
    }

    containerEl.classList.add('kt-scroll');

    const options = Object.assign(
      {},
      {
        wheelSpeed: 0.5,
        swipeEasing: true,
        wheelPropagation: false,
        minScrollbarLength: 40,
        maxScrollbarLength: 300,
      },
      this.props.options,
    );

    this.ps = new PerfectScrollbar(containerEl, options);
  }

  private destroy() {
    this.rs && this.rs.detach(this.onContainerResize);
    this.ps && this.ps.destroy();
    this.ps = null;
    this.rs = null;
  }
}

import classNames from 'classnames';
import React, { ReactNode } from 'react';
import { ResponsiveSizeType, ResponsiveSizeTypeMap } from '../types';

interface Props {
  spacer?: Exclude<ResponsiveSizeType, 'extra-small'>;
  dashed?: boolean;
  children?: ReactNode;
}

export const Separator = React.memo<Props>(props => {
  const { spacer, dashed } = props;
  const spacing = spacer && ResponsiveSizeTypeMap.get(spacer);
  return (
    <div
      className={classNames('kt-separator', {
        [`kt-separator--space-${spacing}`]: spacing,
        'kt-separator--border-solid': !dashed,
        'kt-separator--border-dashed': dashed,
      })}
    >
      {props.children}
    </div>
  );
});

/**
 * @file: item-source-helper.ts
 * @author: eric <xuxiang@zhichetech.com>
 * @copyright: (c) 2019-2020 sichuan zhichetech co., ltd.
 */

import { ItemSourceable } from 'model';
import { ItemSource } from 'shared/types';

export function getItemSource(item: ItemSourceable): ItemSource {
  if (item.agentId) {
    return 'agent';
  }

  if (item.orgId) {
    return item.storeId ? 'store' : 'org';
  }

  return 'system';
}

import classNames from 'classnames';
import React, { PureComponent, HTMLProps } from 'react';
import { getString } from 'shared/components';
import { ColorType } from '../types';

interface Props extends HTMLProps<HTMLInputElement> {
  label?: string;
  solid?: boolean;
  color?: ColorType;
  bold?: boolean;
  tick?: boolean;
}

export class Radio extends PureComponent<Props> {
  render() {
    const { label, solid, disabled, color, bold, tick, ...props } = this.props;
    return (
      <label className={classNames('kt-radio', {
        'kt-radio--disabled': disabled,
        'kt-radio--bold': bold,
        'kt-radio--tick': tick,
        [`kt-radio--solid`]: solid,
        [`kt-radio--${color}`]: color
      })}>
        <input
          type="radio"
          disabled={disabled}
          {...props}
          />
        {label && getString(label)}
        <span />
      </label>
    );
  }
}
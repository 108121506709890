import classNames from 'classnames';
import React, { HTMLProps, PureComponent, ReactElement } from 'react';
import { DocumentTitle, Restricted } from 'shared/components';
import { Alert } from './Alert';
import { Breadcrumb } from './Breadcrumb';
import { Container, ContainerProps } from './Container';
import { SubHeader, SubHeaderProps } from './SubHeader';

interface Props extends HTMLProps<HTMLDivElement> {
  fluid?: boolean;
  error?: Error | null;
  fullAccessRight?: string;
  readonlyAccessRight?: string;
}

export class Page extends PureComponent<Props> {
  static Header = SubHeader;
  static Content = Container;
  static Breadcrumb = Breadcrumb;

  render() {
    const {
      fluid,
      error,
      className,
      fullAccessRight,
      readonlyAccessRight,
      title,
      children,
      ...props
    } = this.props;

    let header: ReactElement<SubHeaderProps> | null = null;
    let content: ReactElement<ContainerProps> | null = null;

    const contents = React.Children.toArray(children);

    const beforeContents: any[] = [];
    const afterContents: any[] = [];

    for (let i = contents.length - 1; i >= 0; i--) {
      const elem = contents[i] as any;
      if (elem && elem.type === SubHeader) {
        header = React.cloneElement(elem, { ...elem.props, gridItem: true });
      } else if (elem && elem.type === Container && !content) {
        content = elem;
      } else if (!content) {
        beforeContents.push(elem);
      } else {
        afterContents.push(elem);
      }
    }

    if (!content) {
      content = <Container fluid gridItem />;
    }

    const containerContents = React.Children.toArray(content.props.children);

    if (beforeContents.length) {
      containerContents.splice(0, 0, ...beforeContents);
    }

    if (afterContents.length) {
      containerContents.push(...afterContents);
    }

    if (error) {
      containerContents.unshift(
        <Alert color="danger" key="error">
          {error.message}
        </Alert>,
      );
    }

    content = React.cloneElement(content, {
      ...content.props,
      fluid: true,
      gridItem: true,
      children: <>{containerContents}</>,
    });

    const accessRights: string[] = [];

    if (fullAccessRight) {
      accessRights.push(fullAccessRight);
    }

    if (readonlyAccessRight) {
      accessRights.push(readonlyAccessRight);
    }

    return (
      <div
        className={classNames(
          className,
          'kt-content kt-grid__item--fluid kt-grid__item kt-grid kt-grid--hor',
          {
            'kt-grid__item--fluid': fluid,
          },
        )}
        {...props}
      >
        <DocumentTitle title={title || ''} />
        {header}
        <Restricted rights={accessRights}>{content}</Restricted>
      </div>
    );
  }
}

import { ReactNode } from 'react';

export type TooltipPlacement = 'top' | 'bottom' | 'left' | 'right' | 'auto';

export const TooltipDarkTemplate = [
  '<div class="tooltip tooltip-dark tooltop-auto-width" role="tooltip">',
    '<div class="arrow"></div>',
    '<div class="tooltip-inner"></div>',
  '</div>'
].join('');

export interface TooltipProps {
  placement: TooltipPlacement | ((elTooltip: HTMLElement, el: HTMLElement) => TooltipPlacement);
  animation?: boolean;
  content?: ReactNode;
  delay?: number | { show?: number; hide?: number };
  html?: boolean;
  template?: string;
  title?: string;
  boundary?: 'viewport' | 'window' | 'scrollParent';
}
/**
 * @file: index.ts
 * @author: eric <xuxiang@zhichetech.com>
 * @copyright: (c) 2019-2020 sichuan zhichetech co., ltd.
 */

import { ListResult } from 'lib';
import { autoActionType } from 'lib/duck/actions/base';
import {
  INVALIDATE_KEYED_LIST,
  KEYED_LIST_CLEAR_SELECTION,
  KEYED_LIST_ITEM_DESELECTED,
  KEYED_LIST_ITEM_SELECTED,
  KEYED_LIST_TOGGLE_ALL_SELECTION,
  LOAD_KEYED_LIST,
  LOAD_KEYED_LIST_FAILED,
  LOAD_KEYED_LIST_FROM_CACHE,
  LOAD_KEYED_LIST_SUCCESS,
  LOAD_MORE_KEYED_LIST,
  LOAD_MORE_KEYED_LIST_FAILED,
  LOAD_MORE_KEYED_LIST_SUCCESS,
  SET_CURRENT_KEYED_LIST_KEY,
  UPDATE_KEYED_LIST_FILTER,
  UPDATE_KEYED_LIST_LIMIT,
  UPDATE_KEYED_LIST_OFFSET,
  UPDATE_KEYED_LIST_TOTAL,
} from 'lib/duck/actions/constants';
import {
  KeyedListResultAction,
  StandardKeyedListAction,
  StandardKeyedListActionBase,
} from 'lib/duck/actions/keyed-list/interfaces';
import {
  AsyncListState,
  KeyedAsyncListState,
  ObjectKeyType,
  StandardAction,
} from 'lib/duck/interfaces';
import { AsyncActionReducer } from '../base/interfaces';
import { KeyedListActionReducersOptions } from './interfaces';

export * from './interfaces';

export function createKeyedAsyncListActionReducers<
  T,
  TState extends KeyedAsyncListState<T, TListKey, TFilter, TKey>,
  TListKey,
  TFilter,
  TKey extends ObjectKeyType = any,
>(
  scope: string,
  initialState: TState,
  options?: KeyedListActionReducersOptions<T, TState, TListKey, TFilter, TKey>,
): AsyncActionReducer<TState> {
  options =
    options ||
    ({} as KeyedListActionReducersOptions<T, TState, TListKey, TFilter, TKey>);

  function stateWithStandardActionUpdated(
    state: TState,
    action: StandardKeyedListActionBase<TListKey>,
    updated:
      | Partial<AsyncListState<T, TFilter, TKey>>
      | ((
          listState: AsyncListState<T, TFilter, TKey>,
        ) => Partial<AsyncListState<T, TFilter, TKey>>),
  ) {
    const { key, rawKey } = action.payload;

    let listState = state.keyedLists?.[key] || null;

    if (!listState) {
      if (options!.getInitialStateForKeyedList) {
        listState = options!.getInitialStateForKeyedList(rawKey);
      } else {
        listState = {
          isLoading: false,
          result: null,
          error: null,
        };
      }
    }

    if (typeof updated === 'function') {
      updated = updated(listState);
    }

    listState = {
      ...listState,
      ...(updated as AsyncListState<T, TFilter, TKey>),
    };

    return {
      ...state,
      keyedLists: { ...(state.keyedLists || {}), [key]: listState },
    };
  }

  function stateWithListResultActionUpdated(
    state: TState,
    action: KeyedListResultAction<TListKey, T>,
    loadingMore?: boolean,
  ) {
    const { error, result } = action.payload!;

    // error loading case
    if (error) {
      if (loadingMore) {
        return stateWithStandardActionUpdated(state, action, listState => ({
          error,
          isLoadingMore: false,
          offset: listState ? listState.offset! - listState.limit! : 0,
        }));
      }
      return stateWithStandardActionUpdated(state, action, {
        error,
        isLoading: false,
      });
    }

    // success case
    let items: T[] = Array.isArray(result)
      ? result
      : (result as ListResult<T>).items;

    const total = Array.isArray(result)
      ? undefined
      : (result as ListResult<T>).total;

    if (loadingMore) {
      return stateWithStandardActionUpdated(state, action, listState => {
        if (!result) {
          throw new Error(`${action.type} returns null`);
        }
        if (options!.pagingMode === 'infinite-scroll') {
          if (options!.contactMoreItems) {
            items = options!.contactMoreItems(items, listState?.result || []);
          } else {
            items = (listState?.result || []).concat(items);
          }
        }
        return {
          isLoadingMore: false,
          error: null,
          result: items,
          total,
          hasMore: listState?.limit ? items.length === listState.limit : true,
        };
      });
    }

    return stateWithStandardActionUpdated(state, action, listState => ({
      isLoading: false,
      error: null,
      result: items,
      reset: undefined,
      total,
      offset: listState.reset ? 0 : listState.offset,
      hasMore: listState.limit ? items.length === listState.limit : true,
    }));
  }

  // eslint-disable-next-line @typescript-eslint/default-param-last
  return (state: TState = initialState, action: StandardAction<any>) => {
    switch (action.type) {
      case autoActionType(scope, LOAD_KEYED_LIST): {
        return stateWithStandardActionUpdated(state, action, {
          isLoading: true,
        });
      }
      case autoActionType(scope, LOAD_KEYED_LIST_SUCCESS): {
        return stateWithListResultActionUpdated(state, action);
      }
      case autoActionType(scope, LOAD_KEYED_LIST_FAILED): {
        return stateWithListResultActionUpdated(state, action);
      }
      case autoActionType(scope, INVALIDATE_KEYED_LIST): {
        const reset = action.payload.extra as boolean;
        return stateWithStandardActionUpdated(state, action, listState => ({
          isLoading: false,
          error: null,
          reset,
          offset: reset ? 0 : listState.offset,
        }));
      }
      case autoActionType(scope, LOAD_MORE_KEYED_LIST): {
        return stateWithStandardActionUpdated(state, action, listState => ({
          isLoadingMore: true,
          error: null,
          offset: listState.offset! + listState.limit!,
        }));
      }
      case autoActionType(scope, LOAD_MORE_KEYED_LIST_SUCCESS): {
        return stateWithListResultActionUpdated(state, action, true);
      }
      case autoActionType(scope, LOAD_MORE_KEYED_LIST_FAILED): {
        return stateWithListResultActionUpdated(state, action, true);
      }
      case autoActionType(scope, UPDATE_KEYED_LIST_FILTER): {
        const filter = (
          action as StandardKeyedListAction<TListKey, Partial<TFilter>>
        ).payload;
        return stateWithStandardActionUpdated(state, action, listState => ({
          filter: Object.assign({}, listState?.filter, filter),
        }));
      }
      case autoActionType(scope, SET_CURRENT_KEYED_LIST_KEY): {
        const key: TListKey = action.payload;
        return { ...state, currentKey: key };
      }
      case autoActionType(scope, LOAD_KEYED_LIST_FROM_CACHE): {
        return stateWithStandardActionUpdated(state, action, {
          result: action.payload.extra,
        });
      }
      case autoActionType(scope, UPDATE_KEYED_LIST_TOTAL): {
        return stateWithStandardActionUpdated(state, action, {
          total: action.payload.extra,
        });
      }
      case autoActionType(scope, UPDATE_KEYED_LIST_OFFSET): {
        return stateWithStandardActionUpdated(state, action, {
          offset: action.payload.extra,
        });
      }
      case autoActionType(scope, UPDATE_KEYED_LIST_LIMIT): {
        return stateWithStandardActionUpdated(state, action, {
          limit: action.payload.extra,
        });
      }
      case autoActionType(scope, KEYED_LIST_ITEM_SELECTED): {
        return stateWithStandardActionUpdated(state, action, listState => {
          const item = action.payload.extra as T;
          let selection = listState.selection || [];
          const key = options!.mapItemKey!(item);
          if (!selection.includes(key)) {
            selection = [...selection, key];
            return { ...listState, selection };
          }
          return listState;
        });
      }
      case autoActionType(scope, KEYED_LIST_ITEM_DESELECTED): {
        return stateWithStandardActionUpdated(state, action, listState => {
          const item = action.payload.extra as T;
          let selection = listState.selection || [];
          const key = options!.mapItemKey!(item);
          if (selection.includes(key)) {
            selection = selection.filter(x => x !== key);
            return { ...listState, selection };
          }
          return listState;
        });
      }
      case autoActionType(scope, KEYED_LIST_TOGGLE_ALL_SELECTION): {
        return stateWithStandardActionUpdated(state, action, listState => {
          const items = listState.result || [];
          const selection = listState.selection || [];
          const isAllSelected = items.every(x =>
            selection.includes(options!.mapItemKey!(x)),
          );
          if (isAllSelected) {
            return { ...listState, selection: [] };
          } else {
            return {
              ...listState,
              selection: items.slice().map(x => options!.mapItemKey!(x)),
            };
          }
        });
      }
      case autoActionType(scope, KEYED_LIST_CLEAR_SELECTION): {
        return stateWithStandardActionUpdated(state, action, { selection: [] });
      }
      default: {
        return state;
      }
    }
  };
}

/**
 * @file ApiServiceHelper
 * @author eric <xiang.xu@beego.io>
 * @copyright (c) 2019-2020 sichuan zhichetech co., ltd..
 */
import { HttpError } from 'lib/restful-client/HttpError';

export const CONTENT_TYPE = 'Content-Type';

// resolve content type header from the given headers collection.
export function resolveContentTypeHeader(
  headers: { [name: string]: string } | undefined | null,
) {
  if (!headers) return CONTENT_TYPE;
  for (const p in headers) {
    if (
      Object.prototype.hasOwnProperty.call(headers, p) &&
      /^content-type$/i.test(p)
    ) {
      return p;
    }
  }
  return CONTENT_TYPE;
}

// parse http response data.
export function parseResponseData(response: any) {
  let result = response.data;
  if (typeof result === 'string') {
    try {
      const temp = JSON.parse(result);
      if (
        temp &&
        (Object.prototype.hasOwnProperty.call(temp, 'response') ||
          Object.prototype.hasOwnProperty.call(temp, 'code'))
      ) {
        result = temp;
      }
    } catch (e) {
      /* noop */
    }
  }
  return result;
}

// make http response error info object.
export function makeError(
  result: { code?: string; msg?: string; message?: string; response?: any },
  response: { status: number; statusText: string },
) {
  const { code, msg, message } = result;
  const err = new HttpError(msg || message);
  err.code = code || 'unknown';
  err.status = response.status;
  err.statusText = response.statusText;
  err.response = result.response;
  return err;
}

export function transformFormUrlEncodedData(obj: any) {
  const formData: string[] = [];
  for (const p in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, p)) {
      let value = obj[p];
      if (void 0 === value || null === value) {
        value = '';
      }
      formData.push(encodeURIComponent(p) + '=' + encodeURIComponent(value));
    }
  }

  return formData.join('&');
}

export function appendQueryString(url: string, name: string, value: any) {
  const index = url.indexOf('?');
  if (index < 0) url += '?';
  else if (index < url.length) url += '&';
  url += `${name}=${encodeURIComponent(value)}`;
  return url;
}

export function joinPath(...paths: string[]) {
  return paths.reduce((result, path) => {
    if (!result) return path;
    if (!path) return result;
    return result.replace(/\/+$/, '') + '/' + path.replace(/^\/+/, '');
  }, '');
}

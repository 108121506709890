import React, { FC, PureComponent, ReactNode } from 'react';
import { Separator } from './Separator';

const Title: FC<{ children?: ReactNode }> = React.memo(props => (
  <div className="kt-section__title">{props.children}</div>
));
const Desc: FC<{ children?: ReactNode }> = React.memo(props => (
  <div className="kt-section__desc">{props.children}</div>
));
const Content: FC<{ children?: ReactNode }> = React.memo(props => (
  <div className="kt-section__content">{props.children}</div>
));
const Info: FC<{ children?: ReactNode }> = React.memo(props => (
  <div className="kt-section__info">{props.children}</div>
));

export class Section extends PureComponent<{ children?: ReactNode }> {
  static Title = Title;
  static Desc = Desc;
  static Content = Content;
  static Info = Info;
  static Separator = Separator;

  render() {
    return <div className="kt-section">{this.props.children}</div>;
  }
}

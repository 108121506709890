import classNames from 'classnames';
import React, { HTMLProps } from 'react';

export interface ContainerProps extends HTMLProps<HTMLDivElement> {
  gridItem?: boolean;
  fluid?: boolean;
}

export const Container = React.memo<ContainerProps>(({ fluid, gridItem, children, ...props }) => (
  <div className={classNames('kt-container', {
    'kt-container--fluid': fluid,
    'kt-grid__item': gridItem,
    'kt-grid__item--fluid': gridItem && fluid
  })} {...props}>
    {children}
  </div>
));
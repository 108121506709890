import classNames from 'classnames';
import React, { FC, HTMLProps, PureComponent, ReactNode } from 'react';
import { ColorType } from '../types';
import { Button } from './Button';

interface Props extends HTMLProps<HTMLDivElement> {
  color: ColorType;
  diagonalBg?: boolean;
}

const CalloutContext = React.createContext({
  title: '',
});

const Content: FC<{ children?: ReactNode }> = React.memo(({ children }) => (
  <CalloutContext.Consumer>
    {({ title }) => (
      <div className="kt-callout__content">
        {title && <h3 className="kt-callout__title">{title}</h3>}
        <div className="kt-callout__desc">{children}</div>
      </div>
    )}
  </CalloutContext.Consumer>
));

const Action: FC<{ children?: ReactNode }> = React.memo(({ children }) => {
  let button: any = null;

  const onlyChild: any = React.Children.count(children)
    ? React.Children.only(children)
    : null;

  if (onlyChild && onlyChild.type === Button) {
    button = React.cloneElement(onlyChild, {
      className: classNames(onlyChild.props.className, 'btn-custom1'),
    });
  }

  return <div className="kt-callout__action">{button}</div>;
});

export class Callout extends PureComponent<Props> {
  static Content = Content;
  static Action = Action;

  render() {
    const { color, diagonalBg, className, children, title, ...props } =
      this.props;
    return (
      <div
        className={classNames(
          'kt-portlet kt-callout',
          {
            [`kt-callout--${color}`]: color,
            [`kt-callout--diagonal-bg`]: diagonalBg,
          },
          className,
        )}
        {...props}
      >
        <div className="kt-portlet__body">
          <div className="kt-callout__body">
            <CalloutContext.Provider value={{ title: title || '' }}>
              {children}
            </CalloutContext.Provider>
          </div>
        </div>
      </div>
    );
  }
}

/**
 * @file: index.ts
 * @author: eric <xuxiang@zhichetech.com>
 * @copyright: (c) 2019-2020 sichuan zhichetech co., ltd.
 */

import {
  ActionThunk,
  DispatchFn,
  FetchCallback,
  ObjectKeyType,
  ObjectMap,
  StandardAction,
} from 'lib/duck/interfaces';
import { cacheService } from '../../../index';
import {
  autoActionType,
  createAsyncActionCreators,
  createStandardAction,
} from '../base';
import {
  CANCEL_OBJECT_BY_KEY_BEING_UPDATED,
  COMMIT_OBJECT_BY_KEY_BEING_UPDATED,
  INVALIDATE_OBJECT_BY_KEY,
  LOAD_OBJECT_BY_KEY,
  LOAD_OBJECT_BY_KEY_FAILED,
  LOAD_OBJECT_BY_KEY_FROM_CACHE,
  LOAD_OBJECT_BY_KEY_SUCCESS,
  OBJECT_BY_KEY_BEING_UPDATED,
  OBJECT_BY_KEY_BEING_UPDATED_CHANGED,
  REMOVE_OBJECT_BY_KEY,
  SET_CURRENT_KEY_OF_OBJECT_BY_KEY,
  UPDATE_OBJECT_BY_KEY,
  UPDATE_OBJECT_BY_KEY_FAILED,
  UPDATE_OBJECT_BY_KEY_SUCCESS,
} from '../constants';
import { ObjectResultAction } from '../object';
import {
  AsyncObjectMapActionCreators,
  AsyncObjectMapActionCreatorsOptions,
  KeyedObjectResultAction,
  KeyedObjectResultActionPayload,
} from './interfaces';

export function createKeyedObjectResultAction<
  TKey extends ObjectKeyType,
  TValue,
>(
  type: string,
  key: TKey,
  error?: Error | null,
  value?: TValue | null,
): KeyedObjectResultAction<TKey, TValue> {
  return createStandardAction<KeyedObjectResultActionPayload<TKey, TValue>>(
    type,
    { key, error, value },
  );
}

// #region async object map action creators
export function createObjectMapAsyncActionCreators<
  TAppState,
  TKey extends ObjectKeyType,
  TValue,
>(
  scope: string,
  options: AsyncObjectMapActionCreatorsOptions<TAppState, TKey, TValue>,
): AsyncObjectMapActionCreators<TAppState, TKey, TValue> {
  if (!options.mapValueToKey) {
    throw new Error('options.mapValueToKey is required for object map');
  }
  const { fetchObjectHandler, updateObjectHandler, onFetchObjectByKeySuccess } =
    options;
  const creators = createAsyncActionCreators<
    TAppState,
    ObjectMap<TValue>,
    ObjectResultAction<ObjectMap<TValue>>,
    TValue
  >(scope, options);

  function loadObjectByKey(key: TKey): StandardAction<TKey> {
    return createStandardAction(autoActionType(scope, LOAD_OBJECT_BY_KEY), key);
  }

  function loadObjectByKeySuccess(
    key: TKey,
    value: TValue,
  ): KeyedObjectResultAction<TKey, TValue> {
    return createKeyedObjectResultAction<TKey, TValue>(
      autoActionType(scope, LOAD_OBJECT_BY_KEY_SUCCESS),
      key,
      null,
      value,
    );
  }

  function loadObjectByKeyFailed(
    key: TKey,
    error: Error,
  ): KeyedObjectResultAction<TKey, TValue> {
    return createKeyedObjectResultAction<TKey, TValue>(
      autoActionType(scope, LOAD_OBJECT_BY_KEY_FAILED),
      key,
      error,
    );
  }

  function invalidateObjectByKey(
    key: TKey,
    callback?: FetchCallback<TValue>,
  ): StandardAction<TKey> | ActionThunk<TAppState> {
    const invalidateObjectKeyAction = createStandardAction(
      autoActionType(scope, INVALIDATE_OBJECT_BY_KEY),
      key,
    );
    if (options.shouldFetchObjectByKeyOnInvalidate) {
      return (dispatch: DispatchFn<TAppState>, _: () => TAppState) => {
        dispatch(invalidateObjectKeyAction);
        dispatch(fetchObjectByKey(key, callback));
      };
    }

    return invalidateObjectKeyAction;
  }

  function updateObjectByKey(
    key: TKey,
    obj: TValue,
  ): StandardAction<{ key: TKey; value: TValue }> {
    return createStandardAction(autoActionType(scope, UPDATE_OBJECT_BY_KEY), {
      key,
      value: obj,
    });
  }

  function updateObjectByKeySuccess(
    key: TKey,
    value: TValue,
  ): KeyedObjectResultAction<TKey, TValue> {
    return createKeyedObjectResultAction<TKey, TValue>(
      autoActionType(scope, UPDATE_OBJECT_BY_KEY_SUCCESS),
      key,
      null,
      value,
    );
  }

  function updateObjectByKeyFailed(
    key: TKey,
    error: Error,
  ): KeyedObjectResultAction<TKey, TValue> {
    return createKeyedObjectResultAction<TKey, TValue>(
      autoActionType(scope, UPDATE_OBJECT_BY_KEY_FAILED),
      key,
      error,
      null,
    );
  }

  function requestUpdateObjectByKey(
    key: TKey,
    obj: TValue,
    ...args: any[]
  ): ActionThunk<TAppState> {
    if (!updateObjectHandler) {
      throw new Error('updateObjectHandler is required but is not provided. ');
    }
    const callback: FetchCallback<TValue> =
      typeof args[args.length - 1] === 'function'
        ? args[args.length - 1]
        : null;
    return dispatch => {
      dispatch(updateObjectByKey(key, obj));
      updateObjectHandler(key, obj, ...args)
        .then(result => {
          dispatch(updateObjectByKeySuccess(key, result));
          callback && callback(null, result);
        })
        .catch(err => {
          dispatch(updateObjectByKeyFailed(key, err));
          callback && callback(err, undefined);
        });
    };
  }

  function objectBeingUpdated(obj: TValue): StandardAction<TValue> {
    return createStandardAction(
      autoActionType(scope, OBJECT_BY_KEY_BEING_UPDATED),
      obj,
    );
  }

  function objectBeingUpdatedChanged(
    obj: Partial<TValue>,
  ): StandardAction<Partial<TValue>> {
    return createStandardAction(
      autoActionType(scope, OBJECT_BY_KEY_BEING_UPDATED_CHANGED),
      obj,
    );
  }

  function commitObjectBeingUpdated():
    | StandardAction<void>
    | ActionThunk<TAppState> {
    return (dispatch, getState) => {
      const state = getState();
      if (!options.getObjectBeingUpdated) {
        throw new Error(`${scope} options.getObjectBeingUpdated is required. `);
      }
      const object = options.getObjectBeingUpdated(state);
      if (!object) {
        throw new Error(`${scope} options.getObjectBeingUpdated returns null`);
      }
      dispatch(
        createStandardAction(
          autoActionType(scope, COMMIT_OBJECT_BY_KEY_BEING_UPDATED),
        ),
      );
      const key = options.mapValueToKey!(object);
      dispatch(requestUpdateObjectByKey(key, object));
    };
  }

  function cancelObjectBeingUpdated(): StandardAction<void> {
    return createStandardAction(
      autoActionType(scope, CANCEL_OBJECT_BY_KEY_BEING_UPDATED),
    );
  }

  function removeObjectByKey(key: TKey): StandardAction<TKey> {
    return createStandardAction(
      autoActionType(scope, REMOVE_OBJECT_BY_KEY),
      key,
    );
  }

  function fetchObjectByKey(key: TKey, ...args: any[]): ActionThunk<TAppState> {
    const callback: FetchCallback<TValue> =
      typeof args[args.length - 1] === 'function'
        ? args[args.length - 1]
        : null;

    return (dispatch, getState) => {
      dispatch(loadObjectByKey(key));

      const state: TAppState = getState();

      const loadObjectByKeyActionType = autoActionType(
        scope,
        LOAD_OBJECT_BY_KEY,
      );

      let fetchedFromServer = false;
      const cacheKey = options.cacheKey
        ? `${options.cacheKey}.${key}`
        : `${loadObjectByKeyActionType}.${key}.cached`;

      if (options.cache && options.get) {
        const result = options.get(state, key);
        if (result === null) {
          cacheService
            .get<TValue>(cacheKey)
            .then(value => {
              if (!fetchedFromServer && value !== undefined) {
                const loadObjectByKeyFromCacheAction =
                  createKeyedObjectResultAction(
                    autoActionType(scope, LOAD_OBJECT_BY_KEY_FROM_CACHE),
                    key,
                    null,
                    value,
                  );
                dispatch(loadObjectByKeyFromCacheAction);
              }
            })
            .catch(() => {
              /* noop */
            });
        }
      }

      args = [state, key, ...args];

      // eslint-disable-next-line prefer-spread
      fetchObjectHandler
        .apply(null, args)
        .then((value: TValue) => {
          fetchedFromServer = true;
          if (options.cache) {
            // save the result to cache.
            cacheService
              .set(cacheKey, value)
              .then(() => {
                console.log('%s saved to cache', loadObjectByKeyActionType);
              })
              .catch(() => {
                /* noop */
              });
          }

          callback && callback(null, value);
          onFetchObjectByKeySuccess &&
            onFetchObjectByKeySuccess(dispatch, getState, value);
          dispatch(loadObjectByKeySuccess(key, value));
        })
        .catch((err: Error) => {
          callback && callback(err, undefined);
          dispatch(loadObjectByKeyFailed(key, err));
        });
    };
  }

  function setCurrentObjectByKey(key: TKey): StandardAction<TKey> {
    return createStandardAction(
      autoActionType(scope, SET_CURRENT_KEY_OF_OBJECT_BY_KEY),
      key,
    );
  }

  return {
    loadObjectByKey,
    loadObjectByKeySuccess,
    loadObjectByKeyFailed,
    invalidateObjectByKey,
    fetchObjectByKey,
    updateObjectByKey,
    updateObjectByKeySuccess,
    updateObjectByKeyFailed,
    requestUpdateObjectByKey,
    objectBeingUpdated,
    objectBeingUpdatedChanged,
    commitObjectBeingUpdated,
    cancelObjectBeingUpdated,
    removeObjectByKey,
    setCurrentObjectByKey,
    ...creators,
  };
}

// #endregion

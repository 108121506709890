import classNames from 'classnames';
import React, { PureComponent, HTMLProps } from 'react';
import { ResponsiveSizeType, StateColorType, ResponsiveSizeTypeMap } from '../types';

export interface BadgeProps extends Omit<HTMLProps<HTMLElement>, 'size' | 'label' | 'ref' | 'value'> {
  color?: StateColorType;
  inline?: boolean;
  pill?: boolean;
  rounded?: boolean;
  square?: boolean;
  size?: ResponsiveSizeType;
  unified?: boolean;
  bold?: boolean;
  dot?: boolean;
  outline?: boolean;
  outline2x?: boolean;
  label?: boolean;
  plain?: boolean;
}

export class Badge extends PureComponent<BadgeProps> {
  static defaultProps: Partial<BadgeProps> = {
    inline: true
  };

  render() {
    const {
      color, inline, pill, rounded, square, size, plain,
      unified, bold, dot, outline, outline2x, className, label, children, ...props
    } = this.props;

    if (plain) {
      return <>{children}</>;
    }

    const sz = size && ResponsiveSizeTypeMap.get(size);

    if (label) {
      return (
        <span className={classNames('btn', {
          [`btn-label-${color}`]: color && !outline,
          [`btn-outline-${color}`]: color && outline && !label,
          'btn-sm': size === 'small',
          'btn-lg': size === 'large',
          'btn-font-sm': size === 'small',
          'btn-font-lg': size === 'large',
          'btn-bold': bold,
          'btn-selectable': true
        })} {...props}>
          {children}
        </span>
      );
    }

    return (
      <span className={classNames('kt-badge', {
        [`kt-badge--${color}`]: !unified,
        [`kt-badge--unified-${color}`]: unified,
        [`kt-badge--${sz}`]: sz && sz !== 'xs',
        'kt-badge--inline': inline,
        'kt-badge--pill': pill,
        'kt-badge--rounded': rounded,
        'kt-badge--square': square,
        'kt-badge--outline': outline,
        'kt-badge--outline-2x': outline2x,
        'kt-badge--bold': bold,
        'kt-badge--dot': dot,
      }, className)} {...props}>
        {children}
      </span>
    );
  }
}
import classNames from 'classnames';
import React, { PureComponent } from 'react';

interface Props {
  logo?: string;
  brand: string;
  onToggleAside?: () => void;
  onToggleRightAside?: () => void;
  onToggleTopbar?: () => void;
}

export class MobileHeader extends PureComponent<Props> {
  render() {
    const { logo, brand, onToggleAside, onToggleRightAside, onToggleTopbar } = this.props;
    return (
      <div className={classNames('kt-header-mobile kt-header-mobile--fixed')}>
        <div className="kt-header-mobile__logo">
          <a href="index.html">
            {logo && <img src={logo} alt={brand} />}
            {!logo && brand}
          </a>
        </div>
        <div className="kt-header-mobile__toolbar">
          <button
            className="kt-header-mobile__toggler kt-header-mobile__toggler--left"
            onClick={onToggleAside}
            >
            <span />
          </button>
          <button className="kt-header-mobile__toggler" onClick={onToggleRightAside}>
            <span />
          </button>
          <button className="kt-header-mobile__topbar-toggler" onClick={onToggleTopbar}>
            <i className="flaticon-more" />
          </button>
        </div>
      </div>
    );
  }
}
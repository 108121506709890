import classNames from 'classnames';
import React, { PureComponent, HTMLProps } from 'react';
import { ColorType, ResponsiveSizeTypeMap } from '../types';

interface Props extends Omit<HTMLProps<HTMLDivElement>, 'size'> {
  color?: ColorType;
  size?: 'small' | 'medium' | 'large';
}

export class Spinner extends PureComponent<Props> {
  static defaultProps: Partial<Props> = {
    color: 'primary'
  };

  render() {
    const { color, size, ...props } = this.props;
    const sz = size && ResponsiveSizeTypeMap.get(size);

    return (
      <div className={classNames('kt-spinner', {
        [`kt-spinner--${sz}`]: sz,
        [`kt-spinner--${color}`]: color
      })} {...props} />
    );
  }
}
import { breakpoints } from './config';
import { Breakpoint, ResponsiveMode } from './types';

// https://stackoverflow.com/questions/51731754/window-scroll-smooth-not-working-on-safari
// https://www.npmjs.com/package/smoothscroll-polyfill
const smoothscroll = require('smoothscroll-polyfill');
smoothscroll.polyfill();

// eslint-disable-next-line @typescript-eslint/no-extraneous-class
export class KtUtil {
  /**
   * Gets window width for give breakpoint mode.
   * @param {string} mode Responsive mode name(e.g: xl, lg, md, sm)
   * @returns {number}
   */
  static getBreakpoint(mode: Breakpoint): number {
    return breakpoints[mode];
  }

  /**
   * Gets browser window viewport size. Ref:
   * http://andylangton.co.uk/articles/javascript/get-viewport-size-javascript/
   * @returns {object}
   */
  static getViewPort(): { width: number; height: number } {
    let e: any = window;
    let a: string = 'inner';
    if (!('innerWidth' in window)) {
      a = 'client';
      e = document.documentElement || document.body;
    }

    return {
      width: e[a + 'Width'] as number,
      height: e[a + 'Height'] as number,
    };
  }

  /**
   * Checks whether given device mode is currently activated.
   * @param {string} mode Responsive mode name(e.g: desktop,
   *     desktop-and-tablet, tablet, tablet-and-mobile, mobile)
   * @returns {boolean}
   */
  static isInResponsiveRange(mode: ResponsiveMode): boolean {
    const breakpoint = KtUtil.getViewPort().width;

    if (mode == 'general') {
      return true;
    } else if (
      mode == 'desktop' &&
      breakpoint >= KtUtil.getBreakpoint('lg') + 1
    ) {
      return true;
    } else if (
      mode == 'tablet' &&
      breakpoint >= KtUtil.getBreakpoint('md') + 1 &&
      breakpoint < KtUtil.getBreakpoint('lg')
    ) {
      return true;
    } else if (mode == 'mobile' && breakpoint <= KtUtil.getBreakpoint('md')) {
      return true;
    } else if (
      mode == 'desktop-and-tablet' &&
      breakpoint >= KtUtil.getBreakpoint('md') + 1
    ) {
      return true;
    } else if (
      mode == 'tablet-and-mobile' &&
      breakpoint <= KtUtil.getBreakpoint('lg')
    ) {
      return true;
    } else if (
      mode == 'minimal-desktop-and-below' &&
      breakpoint <= KtUtil.getBreakpoint('xl')
    ) {
      return true;
    }

    return false;
  }

  static scrollTop() {}
}

import React, { PureComponent, HTMLProps, ReactNode } from 'react';
import { Topbar } from './Topbar';
import { OrgUser } from 'model';
import { MenuNav, Menu, MenuItem } from '../components/Menu';
import { AppLoading } from 'shared/components';
import { AppStatus } from 'shared/types';

interface Props extends HTMLProps<HTMLDivElement> {
  user: OrgUser | null;
  activeStoreId: number | null;
  menu?: Menu;
  showLoading?: boolean;
  loadingMessage?: string | ReactNode;
  loadingStatus?: AppStatus;
  onMenuItemClick?: (item: MenuItem) => void;
}

export class Header extends PureComponent<Props> {
  render() {
    const {
      menu,
      user,
      showLoading,
      loadingMessage,
      loadingStatus,
      activeStoreId,
      onMenuItemClick,
      ...props
    } = this.props;
    return (
      <div className="kt-header kt-grid__item kt-header--fixed" {...props}>
        {menu && (
          <div className="kt-header-menu-wrapper">
            <div className="kt-header-menu kt-header-menu-mobile kt-header-menu--layout-default">
              <MenuNav menu={menu} onMenuItemClick={onMenuItemClick} />
            </div>
          </div>
        )}
        <Topbar user={user} activeStoreId={activeStoreId} />
        <AppLoading
          show={showLoading || false}
          message={loadingMessage}
          status={loadingStatus}
        />
      </div>
    );
  }
}

/**
 * @file: websocket.ts
 * @author: eric <xuxiang@zhichetech.com>
 * @copyright: (c) 2019-2020 sichuan zhichetech co., ltd.
 */

import { storageService, tokenService } from 'lib';
import qs from 'qs';

const maxReconnectAttempts = 1000;

let ws: WebSocket | null = null;
let connected = false;
let reconnectAttempts = 0;

export interface SocketMsg {
  type: string;
  data?: any;
}

export type MessageEventArgs = {
  source: '@zhichetech/store';
  type: 'ws';
  event: SocketMsg;
};

export function sendSocketMsg(msg: SocketMsg) {
  if (!connected || !ws) {
    return;
  }

  const data = JSON.stringify(msg);
  ws.send(data);
}

function onMessage(e: MessageEvent) {
  const msg: SocketMsg = JSON.parse(e.data);
  if (msg.type === 'hello') {
    console.log('handshake with websocket server successful');
    return;
  }
  const args: MessageEventArgs = {
    source: '@zhichetech/store',
    type: 'ws',
    event: msg,
  };
  window.postMessage(args, '*');
}

export function initWebsocket() {
  connect();
}

function connect() {
  const token = tokenService.getToken();
  const clientid = storageService.ss_get<string>('app.clientId');
  const url =
    `wss://${location.host}/ws` + `?` + qs.stringify({ token, clientid });

  ws = new WebSocket(url);

  connected = false;

  ws.addEventListener('open', () => {
    console.log('connected server. ');

    reconnectAttempts = 0;
    connected = true;

    ws!.addEventListener('close', () => {
      connected = false;
      console.log('connection lost');
      reconnectAttempts = 0;
      reconnect();
    });

    ws!.addEventListener('message', onMessage);

    sendSocketMsg({ type: 'hello', data: clientid });
  });

  ws.addEventListener('error', () => {
    if (!connected) {
      setTimeout(() => {
        reconnect();
      }, 3000);
    }
  });
}

function reconnect() {
  if (reconnectAttempts > maxReconnectAttempts) {
    ws = null;
    reconnectAttempts = 0;
    console.error('max reconnect attempts exceeded, closed. ');
    return;
  }
  reconnectAttempts++;
  console.log('reconnecting websocket... #%d', reconnectAttempts);
  connect();
}

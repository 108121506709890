import { storageService } from 'lib';
import { InlineSvg } from 'lib/components';
import React, { MouseEvent, PureComponent } from 'react';
import { Translate } from 'react-localize-redux';

export class Footer extends PureComponent {
  render() {
    return (
      <div className="kt-footer  kt-grid__item kt-grid kt-grid--desktop kt-grid--ver-desktop">
        <div className="kt-container  kt-container--fluid">
          <div className="kt-footer__left">
            <div className="kt-footer__copyright">
              <Translate id="copyright" />
            </div>
            <span className="kt-footer__lang">
              <a href="#" className="kt-nav__link" onClick={this.onLangClick('zh')}>
                <InlineSvg src="public/img/lang-zh-circle.svg" className="kt-footer__lang-icon" />
              </a>
              <a href="#" className="kt-nav__link" onClick={this.onLangClick('en')}>
                <InlineSvg src="public/img/lang-en-circle.svg" className="kt-footer__lang-icon" />
              </a>
            </span>
          </div>
          <div className="kt-footer__menu">
            <a href="#" className="kt-footer__menu-link kt-link">
              <Translate id="foot_links.about" />
            </a>
            <a href="#" className="kt-footer__menu-link kt-link">
              <Translate id="foot_links.privacy" />
            </a>
            <a href="#" className="kt-footer__menu-link kt-link">
              <Translate id="foot_links.terms" />
            </a>
            <a href="#" className="kt-footer__menu-link kt-link">
              <Translate id="foot_links.contact" />
            </a>
          </div>
        </div>
      </div>
    );
  }

  onLangClick(lang: 'en' | 'zh') {
    return (e: MouseEvent<HTMLAnchorElement>) => {
      e.preventDefault();
      storageService.ls_set('lang', lang);
      location.reload();
    };
  }
}
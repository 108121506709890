import React, { PureComponent } from 'react';

interface Props {
  offset?: number;
  speed?: number;
  toggleClass?: string;
  onScrollTop?: () => void;
}

export class ScrollTop extends PureComponent<Props> {
  static defaultProps: Props = {
    offset: 300,
    speed: 600,
    toggleClass: 'kt-scrolltop--on',
  };

  componentDidMount() {
    this.initEvents();
  }

  initEvents() {
    if (/iPhone|iPad|iPod/i.exec(navigator.userAgent)) {
      window.addEventListener('touchend', () => {
        this.onScroll();
      });

      window.addEventListener('touchcancel', () => {
        this.onScroll();
      });

      window.addEventListener('touchleave', () => {
        this.onScroll();
      });
    } else {
      window.addEventListener('scroll', () => {
        this.onScroll();
      });
    }
  }

  render() {
    return (
      <div className="kt-scrolltop" onClick={this.onClick}>
        <i className="fa fa-arrow-up" />
      </div>
    );
  }

  onScroll = () => {
    const { offset, toggleClass } = this.props;
    const pos = window.pageYOffset; // current vertical position
    if (pos > offset!) {
      document.body.classList.add(toggleClass!);
    } else {
      document.body.classList.remove(toggleClass!);
    }
  }

  onClick = () => {
    this.props.onScrollTop && this.props.onScrollTop();
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }
}

/**
 * @file: index.ts
 * @author: eric <xuxiang@zhichetech.com>
 * @copyright: (c) 2019-2020 sichuan zhichetech co., ltd.
 */

import { autoActionType } from 'lib/duck/actions/base';
import {
  INVALIDATE,
  LOAD,
  LOAD_FAILED,
  LOAD_FROM_CACHE,
  LOAD_SUCCESS,
} from 'lib/duck/actions/constants';
import { ObjectResultAction } from 'lib/duck/actions/object';
import { AsyncState, StandardAction } from 'lib/duck/interfaces';
import { AsyncActionReducer, AsyncActionReducersOptions } from './interfaces';

export * from './interfaces';

export function createAsyncActionReducers<
  T,
  TState extends AsyncState<T>,
  TResultAction extends ObjectResultAction<T> = ObjectResultAction<T>,
>(
  scope: string,
  initialState: TState,
  options?: AsyncActionReducersOptions<T, TState>,
): AsyncActionReducer<TState> {
  options = options || ({} as AsyncActionReducersOptions<T, TState>);
  const { onLoad, onLoadSuccess, onLoadFailed, onInvalidate } = options;
  // eslint-disable-next-line @typescript-eslint/default-param-last
  return (state: TState = initialState, action: StandardAction<any>) => {
    switch (action.type) {
      case autoActionType(scope, LOAD): {
        if (state.isLoading) return state;
        state = Object.assign({}, state, { isLoading: true, error: null });
        return onLoad ? onLoad(state) : state;
      }
      case autoActionType(scope, LOAD_FROM_CACHE): {
        const result = action.payload as T;
        return Object.assign({}, state, {
          result,
        });
      }
      case autoActionType(scope, LOAD_SUCCESS): {
        const payload = (action as TResultAction).payload!;
        state = Object.assign({}, state, {
          isLoading: false,
          error: null,
          result: payload.result,
        });
        return onLoadSuccess ? onLoadSuccess(state) : state;
      }
      case autoActionType(scope, LOAD_FAILED): {
        const payload = (action as TResultAction).payload!;
        state = Object.assign({}, state, {
          isLoading: false,
          error: payload.error,
        });
        return onLoadFailed ? onLoadFailed(state) : state;
      }
      case autoActionType(scope, INVALIDATE):
        state = Object.assign({}, state, {
          isLoading: true,
          error: null,
        });
        return onInvalidate ? onInvalidate(state) : state;
    }
    return state;
  };
}

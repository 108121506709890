import classNames from 'classnames';
import React, {
  CSSProperties,
  FC,
  HTMLProps,
  PureComponent,
  ReactNode,
} from 'react';
import { SvgrComponent } from 'src/declarations';
import { ColorType } from '../types';
import { Icon } from './Icon';

interface Props extends HTMLProps<HTMLDivElement> {
  color?: ColorType;
  children?: React.ReactNode;
  icon?: string | SvgrComponent;
  outline?: boolean;
  bold?: boolean;
  solid?: boolean;
  square?: boolean;
  dismissible?: boolean;
  iconColor?: string;
  air?: boolean;
  iconStyle?: CSSProperties;
  textStyle?: CSSProperties;
  style?: CSSProperties;
  onDismiss?: () => void;
}

const Heading: FC<{ children?: ReactNode }> = React.memo(props => (
  <h4 className="alert-heading">{props.children}</h4>
));

export class Alert extends PureComponent<Props> {
  static displayName = 'Alert';

  static Heading = Heading;

  render() {
    const {
      color,
      icon,
      air,
      solid,
      bold,
      square,
      outline,
      dismissible,
      iconColor,
      iconStyle,
      textStyle,
      children,
      className,
      onDismiss,
      ...props
    } = this.props;

    const { fontSize: iconFontSize = void 0, ...iconStyles } = iconStyle || {};

    return (
      <div
        className={classNames(
          'alert',
          {
            [`alert-${color}`]: color && !outline && !solid,
            [`alert-outline-${color}`]: color && outline && !solid,
            [`alert-solid-${color}`]: color && solid && !outline,
            'alert-bold': bold,
            'alert-dismissable': dismissible,
            'alert-air': air,
            'alert-square': square,
          },
          className,
        )}
        {...props}
      >
        {icon ? (
          <>
            {icon && (
              <div className="alert-icon" style={iconStyles}>
                <Icon
                  icon={icon}
                  className={classNames(icon, {
                    [`kt-font-${iconColor}`]: iconColor,
                  })}
                  style={{ fontSize: iconFontSize }}
                />
              </div>
            )}
            <div className="alert-text" style={textStyle}>
              {children}
            </div>
            {dismissible && (
              <div className="alert-close">
                <button
                  type="button"
                  className="close"
                  aria-label="Close"
                  onClick={onDismiss}
                >
                  <span aria-hidden="true">
                    <i className="la la-close" />
                  </span>
                </button>
              </div>
            )}
          </>
        ) : (
          <>
            {children}
            {dismissible && (
              <div className="alert-close">
                <button
                  type="button"
                  className="close"
                  aria-label="Close"
                  onClick={onDismiss}
                >
                  <span aria-hidden="true">
                    <i className="la la-close" />
                  </span>
                </button>
              </div>
            )}
          </>
        )}
      </div>
    );
  }
}

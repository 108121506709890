/**
 * @file: acl.tsx
 * @author: eric <xuxiang@zhichetech.com>
 * @copyright: (c) 2019-2020 sichuan zhichetech co., ltd.
 */

import { Identity } from 'model';
import { Component, createContext, useContext } from 'react';

interface Props {
  TargetComponent: any;
}

export const AclContext = createContext<AclContextProps>(null as any);

export interface AclContextProps {
  identity: Identity;
  $has(...rights: string[]): boolean;
}

export const useAclContext = () => useContext(AclContext);

class AclHOC extends Component<Props> {
  render() {
    const { TargetComponent, ...props } = this.props;
    return (
      <AclContext.Consumer>
        {({ identity, $has }) => (
          <TargetComponent identity={identity} $has={$has} {...props} />
        )}
      </AclContext.Consumer>
    );
  }
}

export function withAcl() {
  return (TargetComponent: any) => {
    return ((props: any) => (
      <AclHOC TargetComponent={TargetComponent} {...props} />
    )) as any;
  };
}

import classNames from 'classnames';
import React, { PureComponent, HTMLProps } from 'react';

interface Props extends Omit<HTMLProps<HTMLDivElement>, 'size'> {
  small?: boolean;
  align?: 'left' | 'center' | 'right';
}

export class PortletFooter extends PureComponent<Props> {
  render() {
    const { small, align, ...props } = this.props;
    return (
      <div className={classNames('kt-portlet__foot', {
        'kt-portlet__foot--sm': small,
        'kt-align-right': align === 'right',
        'kt-align-center': align === 'center'
      })} {...props}>
        {this.props.children}
      </div>
    );
  }
}

(PortletFooter as any).displayName = 'Portlet.Footer';
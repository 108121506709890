import classNames from 'classnames';
import React, { PureComponent, HTMLProps } from 'react';

interface Props extends HTMLProps<HTMLDivElement> {
  direction?: 'vertical' | 'horizontal';
  root?: boolean;
}

interface GridItemProps extends HTMLProps<HTMLDivElement> {
  fluid?: boolean;
  grid?: Props;
}

const GridItem = React.memo<GridItemProps>(({ fluid, className, children, grid, ... props }) => (
  <div className={classNames('kt-grid__item', {
    'kt-grid__item--fluid': fluid,
    'kt-grid': grid,
    'kt-grid--ver': grid && grid.direction === 'vertical',
    'kt-grid--hor': grid && grid.direction === 'horizontal',
    'kt-grid--root': grid && grid.root
  }, className)} {...props}>{children}</div>
));

export class Grid extends PureComponent<Props> {
  static defaultProps: Partial<Props> = { direction: 'vertical' };
  static Item = GridItem;

  render() {
    const { direction, root, className, children, ...props } = this.props;
    return (
      <div className={classNames('kt-grid', {
        'kt-grid--ver': direction === 'vertical',
        'kt-grid--hor': direction === 'horizontal',
        'kt-grid--root': root
      }, className)} {...props}>
        {children}
      </div>
    );
  }
}
import { PureComponent } from 'react';

// @ts-ignore
import ToggleIcon1 from '!@svgr/webpack!../assets/icons/svg/navigation/angle-double-left.svg';

// @ts-ignore
import ToggleIcon2 from '!@svgr/webpack!../assets/icons/svg/navigation/angle-double-right.svg';

interface Props {
  logo: string;
  brand: string;
  onToggleAside: () => void;
}

export class Brand extends PureComponent<Props> {
  render() {
    const { logo, brand } = this.props;
    return (
      <div className="kt-aside__brand kt-grid__item">
        <div className="kt-aside__brand-logo">
          <a href="index.html">
            <img src={logo} alt={brand} />
          </a>
        </div>
        <div className="kt-aside__brand-tools">
          <button
            className="kt-aside__brand-aside-toggler"
            onClick={this.onToggleButtonClick}
          >
            <span>
              <ToggleIcon1 />
            </span>
            <span>
              <ToggleIcon2 />
            </span>
          </button>
        </div>
      </div>
    );
  }

  onToggleButtonClick = () => {
    this.props.onToggleAside();
  };
}

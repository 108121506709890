/**
 * @file: tree-helper.ts
 * @author: eric <xuxiang@zhichetech.com>
 * @copyright: (c) 2019-2020 sichuan zhichetech co., ltd.
 */

export interface SimpleTreeNode<T, TKey> {
  id: TKey;
  data: T;
  children?: Array<SimpleTreeNode<T, TKey>>;
}

export function buildSimpleTreeModel<T, TKey>(
  items: T[],
  itemKey: (item: T) => TKey,
  parentItemKey: (item: T) => TKey | null | undefined,
  nodeHandler?: (node: SimpleTreeNode<T, TKey>) => void,
  isTop?: (item: T) => boolean,
): Array<SimpleTreeNode<T, TKey>> {
  if (!isTop) {
    isTop = x => !parentItemKey(x);
  }
  const nodes: Array<SimpleTreeNode<T, TKey>> = [];
  const map = new Map<TKey, SimpleTreeNode<T, TKey>>();
  for (const item of items) {
    const id = itemKey(item);
    const node: SimpleTreeNode<T, TKey> = { id, data: item };
    map.set(id, node);
    nodeHandler && nodeHandler(node);
  }
  for (const item of items) {
    const id = itemKey(item);
    const node = map.get(id)!;
    if (isTop(item)) {
      nodes.push(node);
    } else {
      const parentId = parentItemKey(item);
      const parentNode = map.get(parentId!);
      if (parentNode) {
        if (!parentNode.children) parentNode.children = [];
        parentNode.children.push(node);
      }
    }
  }
  return nodes;
}

export interface TreeDescriptor<T, TKey, TNode> {
  isTop?: (item: T) => boolean;
  itemKey(item: T): TKey;
  parentItemKey(item: T): TKey | null | undefined;
  createNode(item: T, id: TKey): TNode;
  appendChildNode(node: TNode, childNode: TNode): void;
}

export function buildTreeModel<T, TKey, TNode>(
  items: T[],
  descriptor: TreeDescriptor<T, TKey, TNode>,
): TNode[] {
  const isTop = descriptor.isTop ?? (x => !descriptor.parentItemKey(x));
  const nodes: TNode[] = [];
  const map = new Map<TKey, TNode>();
  for (const item of items) {
    const id = descriptor.itemKey(item);
    const node = descriptor.createNode(item, id);
    map.set(id, node);
  }
  for (const item of items) {
    const id = descriptor.itemKey(item);
    const node = map.get(id)!;
    if (isTop(item)) {
      nodes.push(node);
    } else {
      const parentId = descriptor.parentItemKey(item);
      const parentNode = map.get(parentId!);
      if (parentNode) {
        descriptor.appendChildNode(parentNode, node);
      }
    }
  }
  return nodes;
}

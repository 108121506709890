import React, { ReactNode, PureComponent } from 'react';
import { getViewportHeight } from 'utils/ViewportSize';
import { Scrollable } from '../components/Scrollable';

interface Props {
  children?: ReactNode;
}

export class AsideMenu extends PureComponent<Props> {
  private readonly containerElRef = React.createRef<HTMLDivElement>();
  private menuMarginTop?: number;
  private menuMarginBottom?: number;

  getHeight = () => {
    const elBrand: HTMLElement | null = document.querySelector('.kt-aside__brand');
    if (this.menuMarginTop === undefined || this.menuMarginBottom === undefined) {
      const elMenu: HTMLElement | null = document.querySelector('.kt-aside-menu');
      if (elMenu) {
        const style = getComputedStyle(elMenu);
        this.menuMarginTop = parseInt(style.marginTop, 10) || 0;
        this.menuMarginBottom = parseInt(style.marginBottom, 10) || 0;
      }
    }

    const height = getViewportHeight() - (elBrand ? elBrand.offsetHeight : 0) - (
      (this.menuMarginTop || 0) + (this.menuMarginBottom || 0)
    );

    return height;
  }

  render() {
    return (
      <Scrollable
        containerElRef={this.containerElRef}
        className="kt-aside-menu"
        options={{ suppressScrollX: true }}
        height={this.getHeight}
        >
        {this.props.children}
      </Scrollable>
    );
  }
}
import { snakeCase } from 'change-case';
import { config } from 'config';
import { APIService } from 'lib/restful-client/api.service';
import { APIConfig } from 'lib/restful-client/APIConfig';
import { CacheService } from 'lib/services/cache.service';
import { StorageService } from 'lib/services/storage.service';
import { TokenService } from 'lib/services/token.service';
import { RouteComponentProps } from 'react-router';
import { ObjectMap } from './duck/interfaces';

export type SortDirection = 'asc' | 'desc';

export interface RouteViewProps
  extends RouteComponentProps<ObjectMap<string>> {}

export interface ContinueContext<T = any> {
  url?: string;
  type: string;
  extra: T;
}

export interface SortInfo {
  property: string;
  dir: SortDirection;
}

export const apiConfig: APIConfig = new APIConfig(
  config.apiEndPoint || '/api',
  config.apiTimeout || 60000,
);

export const storageService: StorageService = new StorageService();

const CONTINUE_CONTEXT_KEY = '.continue_context';

export function getContinueContext<T>(): ContinueContext<T> {
  return storageService.ls_get<ContinueContext<T>>(CONTINUE_CONTEXT_KEY);
}

export function saveContinueContext<T>(context: ContinueContext<T>) {
  storageService.ls_set(CONTINUE_CONTEXT_KEY, context);
}

export function clearContinueContext() {
  storageService.ls_remove(CONTINUE_CONTEXT_KEY);
}

export const tokenService: TokenService = new TokenService(storageService);
export const cacheService: CacheService = new CacheService(storageService);

export const api: APIService = new APIService(
  apiConfig,
  cacheService,
  storageService,
  tokenService,
);

export function defineActionTypes<T extends { [name: string]: string }>(
  scope: string,
  actionTypes: T,
): { [K in keyof T]: T[K] } {
  const result: { [K in keyof T]: T[K] } = Object.assign({}, actionTypes);
  for (const p in result) {
    if (Object.prototype.hasOwnProperty.call(result, p)) {
      (result as any)[p as string] = `${scope}.${snakeCase(p)}`;
    }
  }
  return result;
}

export interface ListResult<T> {
  total: number;
  items: T[];
}

import React, { PureComponent, ReactNode } from 'react';
import { MobileHeader } from './MobileHeader';
import { ScrollTop } from './ScrollTop';
import { Aside } from './Aside';
import { MenuNav, Menu, MenuItem } from '../components/Menu';
import { AsideMenu } from './AsideMenu';
import { Header } from './Header';
import { OrgUser } from 'model';
import { AppStatus } from 'shared/types';

export interface LayoutProps {
  user: OrgUser;
  activeStoreId: number | null;
  logo?: string;
  brand: string;
  isAsideOpen: boolean;
  showLoading?: boolean;
  loadingMessage?: string | ReactNode;
  loadingStatus?: AppStatus;
  menu: Menu;
  topMenu?: Menu;
  onTopMenuItemClick?: (item: MenuItem) => void;
  children?: ReactNode;
  onToggleAside: () => void;
  onToggleRightAside?: () => void;
  onToggleTopbar?: () => void;
  onAsideMouseEnter?: () => void;
  onAsideMouseLeave?: () => void;
}

export class Layout extends PureComponent<LayoutProps> {
  render() {
    const {
      logo,
      brand,
      menu,
      topMenu,
      children,
      isAsideOpen,
      onToggleAside,
      onToggleRightAside,
      onToggleTopbar,
      showLoading,
      loadingMessage,
      loadingStatus,
      onAsideMouseEnter,
      onAsideMouseLeave,
      onTopMenuItemClick,
    } = this.props;
    return (
      <>
        <MobileHeader
          logo={logo}
          brand={brand}
          onToggleAside={onToggleAside}
          onToggleRightAside={onToggleRightAside}
          onToggleTopbar={onToggleTopbar}
        />
        <div className="kt-grid kt-grid--hor kt-grid--root">
          <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--ver kt-page">
            <Aside
              logo={logo}
              brand={brand}
              open={isAsideOpen}
              onToggle={onToggleAside}
              onMouseEnter={onAsideMouseEnter}
              onMouseLeave={onAsideMouseLeave}
            >
              <div className="kt-aside-menu-wrapper kt-grid__item kt-grid__item--fluid">
                <AsideMenu>
                  <MenuNav menu={menu} isMainNav />
                </AsideMenu>
              </div>
            </Aside>
            <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor kt-wrapper">
              <Header
                user={this.props.user}
                activeStoreId={this.props.activeStoreId}
                showLoading={showLoading}
                loadingMessage={loadingMessage}
                loadingStatus={loadingStatus}
                menu={topMenu}
                onMenuItemClick={onTopMenuItemClick}
              />
              {children}
            </div>
          </div>
        </div>
        <ScrollTop />
      </>
    );
  }
}

export type Breakpoint = 'xl' | 'lg' | 'md' | 'sm';

export type ResponsiveMode = 'general' | 'desktop' | 'tablet' | 'mobile' |
  'desktop-and-tablet' | 'tablet-and-mobile' | 'minimal-desktop-and-below';

export type LabelFontColorType = 'kt-label-font-color-1' | 'kt-label-font-color-2' |
  'kt-label-font-color-3' | 'kt-label-font-color-4';

export type LabelBgColorType = 'kt-label-bg-color-1' | 'kt-label-bg-color-2' |
  'kt-label-bg-color-3' | 'kt-label-bg-color-4';

export type ShapeFontColorType = 'kt-shape-font-color-1' | 'kt-shape-font-color-2' |
  'kt-shape-font-color-3' | 'kt-shape-font-color-4';

export type ShapeBgColorType = 'kt-shape-bg-color-1' | 'kt-shape-bg-color-2' |
  'kt-shape-bg-color-3' | 'kt-shape-bg-color-4';

// export type BaseColorType =

export type ColorType =  'primary' | 'secondary' | 'success' | 'info' |
  'warning' | 'danger' | 'brand' | 'dark' | 'light';

export type BrandColorType = 'facebook' | 'twitter' | 'google' | 'instagram' | 'linkedin';

export const BrandColorTypeSet = new Set<BrandColorType>([
  'facebook', 'twitter', 'google', 'instagram', 'linkedin'
]);

export type ButtonColorType = ColorType | BrandColorType;

export type StateColorType = 'success' | 'warning' | 'danger' | 'info' | 'primary' |
  'brand' | 'dark' | 'light';

export type ResponsiveSizeType = 'extra-small' | 'small' | 'medium' | 'large' | 'extra-large';

export const ResponsiveSizeTypeMap = new Map<ResponsiveSizeType, string>([
  ['extra-small', 'xs'], ['small', 'sm'], ['medium', 'md'],
  ['large', 'lg'], ['extra-large', 'xl']
]);
import classNames from 'classnames';
import { OrgUser } from 'model';
import { Component, MouseEvent } from 'react';
import { Alert, Badge, Button, Dropdown, TabNav } from '../components';

// @ts-ignore
import QuickActionsIcon from '!@svgr/webpack!../assets/icons/svg/layout/layout-4-blocks.svg';
import { tokenService } from 'lib';
import { Translate } from 'react-localize-redux';
import { organizationService } from 'services';
import { AsideRight } from './AsideRight';

interface Props {
  user: OrgUser | null;
  activeStoreId?: number | null;
}

interface State {
  showInvite: boolean;
  isLoadingInviteLink: boolean;
  loadInviteLinkError: Error | null;
  inviteLink: string;
}

export class Topbar extends Component<Props, State> {
  state: State = {
    showInvite: false,
    isLoadingInviteLink: false,
    loadInviteLinkError: null,
    inviteLink: '',
  };

  componentDidUpdate(prevProps: Props) {
    if (this.props.activeStoreId !== prevProps.activeStoreId) {
      this.setState({
        inviteLink: '',
        isLoadingInviteLink: false,
        loadInviteLinkError: null,
      });
    }
  }
  render() {
    const { user } = this.props;
    const displayName = user ? user.nick || user.userName : '';
    return (
      <div className="kt-header__topbar">
        <div className="kt-header__topbar-item">
          <span className="kt-header__topbar-icon">
            <QuickActionsIcon className="kt-svg-icon" />
          </span>
        </div>
        <Dropdown
          className="kt-header__topbar-item kt-header__topbar-item--user"
          dropdownProps={{
            placement: 'bottom-end',
            animated: true,
            fit: true,
            topSquared: true,
            size: 'extra-large',
            style: { marginTop: '0 !important' },
          }}
          renderCustomButton={() => (
            <div className="kt-header__topbar-wrapper">
              <div className="kt-header__topbar-user">
                <span className="kt-header__topbar-welcome kt-hidden-mobile">
                  Hi,
                </span>
                <span className="kt-header__topbar-username kt-hidden-mobile">
                  {displayName}
                </span>
                <img
                  src={this.getAvatar()}
                  alt={displayName}
                  className={classNames({
                    'kt-hidden': !user?.avatarUrl,
                  })}
                />
                <Badge
                  className={classNames('kt-badge--username', {
                    'kt-hidden': user?.avatarUrl,
                  })}
                  color="success"
                  size="large"
                  unified
                  rounded
                  bold
                >
                  {displayName.charAt(0).toUpperCase()}
                </Badge>
              </div>
            </div>
          )}
        >
          <div className="kt-header__topbar-user-dropdown">
            <div
              className="kt-user-card kt-user-card--skin-dark kt-notification-item-padding-x"
              style={{
                backgroundImage: 'url("/public/img/header-user-bg.jpg")',
              }}
            >
              <div className="kt-user-card__avatar">
                {user?.avatarUrl && <img src={user.avatarUrl} alt="Avatar" />}
                {user && !user.avatarUrl && (
                  <Badge size="large" rounded bold className="kt-font-success">
                    {user.userName.charAt(0).toUpperCase()}
                  </Badge>
                )}
              </div>
              <div className="kt-user-card__name">{displayName}</div>
              <div className="kt-user-card__badge">
                <span className="btn btn-success btn-sm btn-bold btn-font-md">
                  <Translate
                    id="header.button.new_msg"
                    data={{ msg_count: 0 }}
                  />
                </span>
              </div>
            </div>
            <div className="kt-notification">
              <div className="kt-notification__item">
                <div className="kt-notification__item-icon">
                  <i className="flaticon2-calendar-3 kt-font-success" />
                </div>
                <div className="kt-notification__item-details">
                  <div className="kt-notification__item-title kt-font-bold">
                    <Translate id="header.user.profile.title" />
                  </div>
                  <div className="kt-notification__item-time">
                    <Translate id="header.user.profile.desc" />
                  </div>
                </div>
              </div>
              <div className="kt-notification__item">
                <div className="kt-notification__item-icon">
                  <i className="flaticon2-mail kt-font-warning" />
                </div>
                <div className="kt-notification__item-details">
                  <div className="kt-notification__item-title kt-font-bold">
                    <Translate id="header.user.messages.title" />
                  </div>
                  <div className="kt-notification__item-time">
                    <Translate id="header.user.messages.desc" />
                  </div>
                </div>
              </div>
              <div className="kt-notification__item" onClick={this.onInvite}>
                <div className="kt-notification__item-icon">
                  <i className="flaticon2-avatar kt-font-brand" />
                </div>
                <div className="kt-notification__item-details">
                  <div className="kt-notification__item-title kt-font-bold">
                    <Translate id="header.user.invite.title" />
                  </div>
                  <div className="kt-notification__item-time">
                    <Translate id="header.user.invite.desc" />
                  </div>
                </div>
              </div>
              <div className="kt-notification__custom kt-space-between">
                <Button
                  label
                  color="brand"
                  size="small"
                  bold
                  onClick={this.onLogout}
                >
                  <Translate id="header.button.logout" />
                </Button>
                <Button clean size="small" bold onClick={this.onSwitchUser}>
                  <Translate id="header.button.switch_user" />
                </Button>
              </div>
            </div>
          </div>
        </Dropdown>
        {this.renderInviteSidebar()}
      </div>
    );
  }

  renderInviteSidebar() {
    return (
      <AsideRight
        open={this.state.showInvite}
        onClose={() => this.setState({ showInvite: false })}
      >
        <AsideRight.Nav>
          <TabNav line="brand" bolder>
            <TabNav.Item active>
              <Translate id="org_member.invite_user.title" />
            </TabNav.Item>
          </TabNav>
        </AsideRight.Nav>
        <AsideRight.Content>
          {this.renderInviteSidebarContent()}
        </AsideRight.Content>
      </AsideRight>
    );
  }

  renderInviteSidebarContent() {
    const { isLoadingInviteLink, loadInviteLinkError, inviteLink } = this.state;
    return (
      <div
        className="invite-sidebar"
        style={{
          padding: 16,
        }}
      >
        <p>
          <Translate id="org_member.invite_user.desc" />
        </p>
        {isLoadingInviteLink ? <p>loading... </p> : null}
        {loadInviteLinkError ? (
          <Alert color="danger" style={{ margin: 0 }}>
            {loadInviteLinkError.message}
          </Alert>
        ) : null}
        {inviteLink && (
          <div className="form-group">
            <label>Invite link</label>
            <div className="input-group">
              <input
                type="text"
                className="form-control"
                defaultValue={inviteLink}
                key={inviteLink}
              />
              <div className="input-group-append">
                <button
                  className="btn btn-primary"
                  type="button"
                  onClick={this.onCopyInviteLink}
                >
                  Copy
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }

  onLogout = (e: MouseEvent) => {
    e.preventDefault();
    this.logout();
  };

  onSwitchUser = (e: MouseEvent) => {
    e.preventDefault();
    this.logout();
  };

  onInvite = async (e: MouseEvent) => {
    e.preventDefault();
    this.setState({ showInvite: true });
    if (this.state.inviteLink) {
      return;
    }
    this.setState({ isLoadingInviteLink: true, loadInviteLinkError: null });
    try {
      const inviteLink = await organizationService.getInviteLink(
        this.props.activeStoreId || undefined,
      );
      this.setState({
        isLoadingInviteLink: false,
        inviteLink,
      });
    } catch (err) {
      this.setState({
        isLoadingInviteLink: false,
        loadInviteLinkError: err,
      });
    }
  };

  onCopyInviteLink = () => {
    if (!navigator.clipboard?.writeText) {
      alert(
        'navigator.clipboard.writeText is not supported in your browser. ' +
          'But you can copy and paste the invite link manually. ',
      );
      return;
    }
    void navigator.clipboard.writeText(this.state.inviteLink);
  };

  private logout() {
    tokenService.removeToken();
    location.href = '/';
  }

  private getAvatar() {
    return (
      this.props.user?.avatarUrl || require('../assets/img/default-user.jpg')
    );
  }
}

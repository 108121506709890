/**
 * @file: index.ts
 * @author: eric <xuxiang@zhichetech.com>
 * @copyright: (c) 2019-2020 sichuan zhichetech co., ltd.
 */

import { autoActionType } from 'lib/duck/actions/base';
import {
  CANCEL_OBJECT_BY_KEY_BEING_UPDATED,
  COMMIT_OBJECT_BY_KEY_BEING_UPDATED,
  INVALIDATE_OBJECT_BY_KEY,
  LOAD_OBJECT_BY_KEY,
  LOAD_OBJECT_BY_KEY_FAILED,
  LOAD_OBJECT_BY_KEY_FROM_CACHE,
  LOAD_OBJECT_BY_KEY_SUCCESS,
  OBJECT_BY_KEY_BEING_UPDATED,
  OBJECT_BY_KEY_BEING_UPDATED_CHANGED,
  REMOVE_OBJECT_BY_KEY,
  SET_CURRENT_KEY_OF_OBJECT_BY_KEY,
  UPDATE_OBJECT_BY_KEY,
  UPDATE_OBJECT_BY_KEY_FAILED,
  UPDATE_OBJECT_BY_KEY_SUCCESS,
} from 'lib/duck/actions/constants';
import { ObjectResultAction } from 'lib/duck/actions/object';
import {
  AsyncObjectMapState,
  AsyncObjectStatus,
  ObjectKeyType,
  ObjectMap,
  StandardAction,
} from 'lib/duck/interfaces';
import { createAsyncActionReducers } from '../base';
import { AsyncActionReducer } from '../base/interfaces';
import { AsyncObjectMapActionReducersOptions } from './interfaces';

import {
  KeyedObjectResultActionPayload,
  KeyValue,
} from 'lib/duck/actions/keyed-object/interfaces';

export * from './interfaces';

export function createAsyncObjectMapActionReducers<
  TKey extends ObjectKeyType,
  TValue,
  TState extends AsyncObjectMapState<TKey, TValue>,
>(
  scope: string,
  initialState: TState,
  options?: AsyncObjectMapActionReducersOptions<TKey, TValue, TState>,
): AsyncActionReducer<TState> {
  options =
    options ||
    ({} as AsyncObjectMapActionReducersOptions<TKey, TValue, TState>);
  if (!initialState) {
    initialState = {
      isLoading: false,
      error: null,
      result: {},
      statusMap: {},
    } as TState;
  }

  if (!initialState.result) initialState.result = {};

  if (!initialState.statusMap) initialState.statusMap = {};

  const next = createAsyncActionReducers<
    ObjectMap<TValue>,
    TState,
    ObjectResultAction<ObjectMap<TValue>>
  >(scope, initialState, options);

  const {
    onLoadObjectByKey,
    onLoadObjectByKeySuccess,
    onLoadObjectByKeyFailed,
    onInvalidateObjectByKey,
  } = options;

  // eslint-disable-next-line @typescript-eslint/default-param-last
  return (state: TState = initialState, action: StandardAction<any>) => {
    switch (action.type) {
      case autoActionType(scope, LOAD_OBJECT_BY_KEY): {
        const key = action.payload as ObjectKeyType;
        if (!state.statusMap) state.statusMap = {};
        if (!state.statusMap[key]) {
          state.statusMap[key] = {};
        }
        const entryStatus = Object.assign({}, state.statusMap[key], {
          isLoading: true,
          error: null,
        });
        state = Object.assign({}, state, {
          statusMap: Object.assign(state.statusMap, { [key]: entryStatus }),
        });
        return onLoadObjectByKey
          ? onLoadObjectByKey(state, key as TKey)
          : state;
      }
      case autoActionType(scope, LOAD_OBJECT_BY_KEY_FROM_CACHE): {
        const payload = action.payload as KeyedObjectResultActionPayload<
          TKey,
          TValue
        >;
        const key = payload.key as ObjectKeyType;
        const cachedValue = payload.value as TValue;
        const result = Object.assign({}, state.result, {
          [key]: cachedValue,
        });
        return Object.assign({}, state, { result });
      }
      case autoActionType(scope, LOAD_OBJECT_BY_KEY_SUCCESS): {
        const payload = action.payload as KeyedObjectResultActionPayload<
          TKey,
          TValue
        >;
        const key = payload.key as ObjectKeyType;
        const entryStatus = Object.assign({}, state.statusMap[key], {
          isLoading: false,
          error: null,
        });
        const result = Object.assign({}, state.result, {
          [key]: payload.value,
        });
        const statusMap = Object.assign({}, state.statusMap, {
          [key]: entryStatus,
        });
        state = Object.assign({}, state, { result, statusMap });
        return onLoadObjectByKeySuccess
          ? onLoadObjectByKeySuccess(state, key as TKey)
          : state;
      }
      case autoActionType(scope, LOAD_OBJECT_BY_KEY_FAILED): {
        const payload = action.payload as KeyedObjectResultActionPayload<
          TKey,
          TValue
        >;
        const key = payload.key as ObjectKeyType;
        const entryStatus = Object.assign({}, state.statusMap[key], {
          isLoading: false,
          error: payload.error,
        });
        const statusMap = Object.assign({}, state.statusMap, {
          [key]: entryStatus,
        });
        state = Object.assign({}, state, { statusMap });
        return onLoadObjectByKeyFailed
          ? onLoadObjectByKeyFailed(state, key as TKey)
          : state;
      }
      case autoActionType(scope, INVALIDATE_OBJECT_BY_KEY): {
        const key = action.payload as ObjectKeyType;
        const entryStatus = Object.assign({}, state.statusMap[key], {
          isLoading: false,
          error: null,
        });
        const result = Object.assign({}, state.result, {
          [key]: null,
        });
        const statusMap = Object.assign({}, state.statusMap, {
          [key]: entryStatus,
        });
        state = Object.assign({}, state, { result, statusMap });
        return onInvalidateObjectByKey
          ? onInvalidateObjectByKey(state, key as TKey)
          : state;
      }
      case autoActionType(scope, UPDATE_OBJECT_BY_KEY): {
        const { key } = action.payload as KeyValue<TKey, TValue>;
        return Object.assign({}, state, {
          isUpdating: true,
          lastUpdateError: null,
          lastUpdateObjectKey: key,
          updateStatus: Object.assign({}, state.updateStatus, {
            [key]: { requesting: true, error: null },
          }),
        } as Partial<AsyncObjectMapState<TKey, TValue>>);
      }
      case autoActionType(scope, UPDATE_OBJECT_BY_KEY_SUCCESS): {
        const payload = action.payload as KeyedObjectResultActionPayload<
          TKey,
          TValue
        >;
        const { key, value } = payload;
        return {
          ...state,
          isUpdating: false,
          lastUpdateError: null,
          lastUpdateObjectKey: key,
          isCommitingObjectBeingUpdated: false,
          isObjectBeingUpdatedDirty: false,
          objectBeingUpdated: null,
          updateStatus: Object.assign({}, state.updateStatus, {
            [key]: { requesting: false, error: null },
          }),
          result: {
            ...(state.result || {}),
            [key]: Object.assign({}, state.result && state.result[key], value),
          },
        };
      }
      case autoActionType(scope, UPDATE_OBJECT_BY_KEY_FAILED): {
        const payload = action.payload as KeyedObjectResultActionPayload<
          TKey,
          TValue
        >;
        const { key, error } = payload;
        return {
          ...state,
          isUpdating: false,
          isCommitingObjectBeingUpdated: false,
          lastUpdateError: error,
          lastUpdateObjectKey: key,
          updateStatus: Object.assign({}, state.updateStatus, {
            [key]: { requesting: false, error },
          }),
        };
      }
      case autoActionType(scope, OBJECT_BY_KEY_BEING_UPDATED): {
        const value = action.payload as TValue;
        return {
          ...state,
          objectBeingUpdated: value,
          isObjectBeingUpdatedDirty: false,
        };
      }
      case autoActionType(scope, OBJECT_BY_KEY_BEING_UPDATED_CHANGED): {
        const changes = action.payload as Partial<TValue>;
        if (!state.objectBeingUpdated) return state;
        return {
          ...state,
          objectBeingUpdated: Object.assign(
            {},
            state.objectBeingUpdated,
            changes,
          ),
          isObjectBeingUpdatedDirty: true,
        };
      }
      case autoActionType(scope, COMMIT_OBJECT_BY_KEY_BEING_UPDATED): {
        if (!state.objectBeingUpdated) return state;
        return {
          ...state,
          isCommitingObjectBeingUpdated: true,
        };
      }
      case autoActionType(scope, CANCEL_OBJECT_BY_KEY_BEING_UPDATED): {
        if (!state.objectBeingUpdated) return state;
        return {
          ...state,
          objectBeingUpdated: null,
          isCommitingObjectBeingUpdated: false,
          isObjectBeingUpdatedDirty: false,
        };
      }
      case autoActionType(scope, REMOVE_OBJECT_BY_KEY): {
        const key = String(action.payload as ObjectKeyType);
        const statusMap = Object.keys(state.statusMap)
          .filter(x => x !== key)
          .reduce<ObjectMap<AsyncObjectStatus>>((map, k) => {
            map[k] = state.statusMap[k];
            return map;
          }, {});
        const result =
          state.result &&
          Object.keys(state.result)
            .filter(x => x !== key)
            .reduce<ObjectMap<TValue>>((map, k) => {
              map[k] = state.result![k];
              return map;
            }, {});
        return Object.assign({}, state, { result, statusMap });
      }
      case autoActionType(scope, SET_CURRENT_KEY_OF_OBJECT_BY_KEY): {
        return Object.assign({}, state, { currentKey: action.payload });
      }
      default:
        return next(state, action);
    }
  };
}

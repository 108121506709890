import React, { PureComponent, HTMLProps } from 'react';
import classNames from 'classnames';

interface Props extends HTMLProps<HTMLDivElement> {
  noPadding?: boolean;
}

export class PortletBody extends PureComponent<Props> {
  render() {
    const { noPadding, className, ...props } = this.props;
    return (
      <div
        className={classNames('kt-portlet__body', className, {
          'kt-portlet__body--no-padding': noPadding
        })}
        {...props}
        >
        {this.props.children}
      </div>
    );
  }
}
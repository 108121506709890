import classNames from 'classnames';
import React, { HTMLProps, PureComponent, ReactNode } from 'react';
import { Button, ButtonProps } from './Button';
import { Container } from './Container';

export interface SubHeaderProps extends HTMLProps<HTMLDivElement> {
  gridItem?: boolean;
  fluid?: boolean;
}

interface MainProps extends Omit<HTMLProps<HTMLDivElement>, 'title'> {
  title?: ReactNode;
}

interface GroupProps extends HTMLProps<HTMLDivElement> {
  desc?: ReactNode;
}

interface ToolbarProps extends HTMLProps<HTMLDivElement> {}

const Toolbar = React.memo<ToolbarProps>(({ children, ...props }) => (
  <div className="kt-subheader__toolbar" {...props}>
    <div className="kt-subheader__wrapper">{children}</div>
  </div>
));

const Main = React.memo<MainProps>(
  ({ className, title, children, ...props }) => (
    <div className={classNames('kt-subheader__main', className)} {...props}>
      {title && <h3 className="kt-subheader__title">{title}</h3>}
      {title && (
        <span className="kt-subheader__separator kt-subheader__separator--v" />
      )}
      {children}
    </div>
  ),
);

const Group = React.memo<GroupProps>(
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  ({ className, desc, children, ...props }) => (
    <div className={classNames('kt-subheader__group', className)} {...props}>
      {desc && <span className="kt-subheader__desc">{desc}</span>}
    </div>
  ),
);

const ToolbarButton = React.memo<ButtonProps>(
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  ({ className, forwardedRef, ref, ...props }) => {
    return (
      <Button
        forwardedRef={forwardedRef}
        className={classNames(className, {
          [`kt-subheader__btn-${props.color}`]: props.color,
        })}
        {...props}
      />
    );
  },
);

export class SubHeader extends PureComponent<SubHeaderProps> {
  static Main = Main;
  static Group = Group;
  static Toolbar = Toolbar;
  static ToolbarButton = ToolbarButton;

  render() {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { className, gridItem, fluid, children, ...props } = this.props;
    return (
      <div
        className={classNames(
          'kt-subheader',
          {
            'kt-grid__item': gridItem,
          },
          className,
        )}
        {...props}
      >
        <Container fluid>{children}</Container>
      </div>
    );
  }
}

/**
 * @file cache.service.ts
 * @author eric <xiang.xu@beego.io>
 * @copyright (c) 2019-2020 sichuan zhichetech co., ltd..
 */

import { StorageService } from './storage.service';

const CACHE_ENTRY_KEY_PREFIX = '_cache_entry';

function buildKey(key: string): string {
  return `${CACHE_ENTRY_KEY_PREFIX}_${key}`;
}

interface CacheEntry<T> {
  expires: number;
  item: T;
}

/**
 * @class Represents the cache service.
 */
export class CacheService {
  private readonly _cache: Map<string, any> = new Map();

  constructor(public storage: StorageService) {}

  /**
   * Get the cached item.
   * @param {String} key cache key
   * @returns {*} cached item
   */
  async get<T>(key: string): Promise<T | undefined> {
    key = buildKey(key);
    let data = this._cache.get(key);
    if (!data) {
      data = this.storage.ls_get<CacheEntry<T>>(key);
    }
    if (!data) return;
    if (data.expires && data.expires < Date.now()) {
      this.storage.ls_remove(key);
      return;
    }
    return data.item;
  }

  /**
   * Set cached item w/ the optional expiration.
   * @param {String} key cache key
   * @param {*} item item to cache.
   * @param {Number} [expires=0] cache expiry, 0 to not expire.
   */
  async set<T>(key: string, item: T, expires = 0): Promise<void> {
    key = buildKey(key);
    const entry: CacheEntry<T> = { expires, item };
    try {
      this.storage.ls_set(key, entry);
    } catch (e) {
      console.warn(e.message);
    }
    this._cache.set(key, entry);
  }

  /**
   * Remove the cache by the given key.
   * @param {String} key cache key to remove
   */
  async remove(key: string): Promise<void> {
    key = buildKey(key);
    this.storage.ls_remove(key);
    this._cache.delete(key);
  }

  /**
   * Clear catch.
   */
  async clear(): Promise<void> {
    for (const p in localStorage) {
      if (
        Object.prototype.hasOwnProperty.call(localStorage, p) &&
        p.startsWith(CACHE_ENTRY_KEY_PREFIX)
      ) {
        localStorage.removeItem(p);
      }
    }
    this._cache.clear();
  }

  /**
   * Calculate the size of the cached entries.
   * @returns {number}
   */
  async size(): Promise<number> {
    let size = 0;
    for (const p in localStorage) {
      if (
        Object.prototype.hasOwnProperty.call(localStorage, p) &&
        p.startsWith(CACHE_ENTRY_KEY_PREFIX)
      ) {
        size += localStorage[p].length * 2;
      }
    }
    return size;
  }
}

import classNames from 'classnames';
import React, { HTMLProps, MouseEvent, PureComponent, ReactNode } from 'react';
import { Dropdown, DropdownChildrenRenderType } from './Dropdown';
import { Nav } from './Nav';

const DEFAULT_LIMITS = [5, 10, 15, 20, 25, 30, 35, 40, 50, 100];

interface Props extends HTMLProps<HTMLDivElement> {
  offset: number;
  limit: number;
  total: number;
  maxPagerItems: number;
  limits?: number[];
  onOffsetChange?: (offset: number) => void;
  onLimitChange?: (limit: number) => void;
  renderRecordInfo?: (
    start: number,
    end: number,
    total: number,
  ) => string | ReactNode;
}

interface PagerItem {
  type:
    | 'first'
    | 'prev'
    | 'more-prev'
    | 'pageno'
    | 'more-next'
    | 'next'
    | 'last';
  text?: string;
  title?: string;
  icon?: string;
  active?: boolean;
  disabled?: boolean;
  offset: () => number;
}

interface PagerLinkProps {
  item: PagerItem;
  onClick: (item: PagerItem) => void;
}

const PagerLink = (props: PagerLinkProps) => {
  const { item } = props;

  const onClick = (e: MouseEvent<HTMLElement>) => {
    e.preventDefault();
    props.onClick && props.onClick(item);
  };

  return (
    <a
      title={item.title}
      className={classNames({
        'kt-datatable__pager-link': true,
        'kt-datatable__pager-link--first': item.type === 'first',
        'kt-datatable__pager-link--prev': item.type === 'prev',
        'kt-datatable__pager-link--more-prev': item.type === 'more-prev',
        'kt-datatable__pager-link-number': item.type === 'pageno',
        'kt-datatable__pager-link--active': item.active,
        'kt-datatable__pager-link--more-next': item.type === 'more-next',
        'kt-datatable__pager-link--next': item.type === 'next',
        'kt-datatable__pager-link--last': item.type === 'last',
        'kt-datatable__pager-link--disabled': item.disabled,
      })}
      onClick={onClick}
      data-page={item.text}
    >
      {item.icon ? <i className={item.icon} /> : item.text}
    </a>
  );
};

interface PageSizeNavItemProps {
  value: number;
  active: boolean;
  onClick: (value: number) => void;
}

const PageSizeNavItem = React.memo<PageSizeNavItemProps>(props => {
  const { value, active } = props;
  const onClick = (e: MouseEvent<HTMLElement>) => {
    e.preventDefault();
    props.onClick(value);
  };
  return (
    <Nav.Item key={value} text={value} active={active} onClick={onClick} />
  );
});

export class Pager extends PureComponent<Props> {
  constructor(props: Props) {
    super(props);
    if (props.limit <= 0 || props.limit % 1 !== 0) {
      throw new Error('pager limit should be positive integer. ');
    }
    if (props.maxPagerItems <= 0 || props.maxPagerItems % 1 !== 0) {
      throw new Error('max page items limit should be positive integer. ');
    }
  }

  render() {
    const {
      total,
      offset,
      limit,
      className,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      maxPagerItems,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      onOffsetChange,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      onLimitChange,
      renderRecordInfo,
      ...props
    } = this.props;
    const pagerItems = this.calcPagerItems();
    const limits = this.props.limits || DEFAULT_LIMITS;
    return (
      <div
        className={classNames(
          'kt-datatable__pager kt-datatable--paging-loaded',
          className,
        )}
        {...props}
      >
        <ul className="kt-datatable__pager-nav">
          {pagerItems.map((item, i) => {
            return (
              <li key={i}>
                <PagerLink item={item} onClick={this.onPagerLinkClick} />
              </li>
            );
          })}
        </ul>
        <div className="kt-datatable__pager-info">
          <Dropdown
            dropdownProps={{ placement: 'top-start', width: 'match-button' }}
            color="light"
            className="d-inline-block kt-datatable__pager-size bootstrap-select"
            style={{ minWidth: '60px' }}
          >
            {(type: DropdownChildrenRenderType) => {
              if (type === 'button-contents') {
                return (
                  <div className="filter-option">
                    <div className="filter-option-inner">
                      <div className="filter-option-inner-inner">{limit}</div>
                    </div>
                  </div>
                );
              }
              return (
                <Nav>
                  {limits.map(value => (
                    <PageSizeNavItem
                      key={value}
                      value={value}
                      active={value === limit}
                      onClick={this.onPageSizeClick}
                    />
                  ))}
                </Nav>
              );
            }}
          </Dropdown>
          &nbsp;
          <span className="kt-datatable__pager-detail">
            {renderRecordInfo
              ? renderRecordInfo(offset + 1, offset + limit, total)
              : this.renderRecordInfoDefault(offset + 1, offset + limit, total)}
          </span>
        </div>
      </div>
    );
  }

  renderRecordInfoDefault(start: number, end: number, total: number): string {
    return `Displaying ${start}-${end} of ${total} records`;
  }

  onPagerLinkClick = (item: PagerItem) => {
    if (item.disabled || item.active) return;
    const offset = item.offset();
    this.props.onOffsetChange && this.props.onOffsetChange(offset);
  };

  onPageSizeClick = (value: number) => {
    if (value !== this.props.limit) {
      this.props.onLimitChange && this.props.onLimitChange(value);
    }
  };

  calcPagerItems(): PagerItem[] {
    const { total, offset, limit, maxPagerItems } = this.props;
    const pageIndex = Math.floor(offset / limit);
    const pageCount = Math.ceil(total / limit);
    const k = Math.floor(pageIndex / maxPagerItems);
    const start = k * maxPagerItems;
    const end = Math.min((k + 1) * maxPagerItems, pageCount);

    const items: PagerItem[] = [];

    items.push(
      {
        type: 'first',
        disabled: pageIndex === 0,
        icon: 'flaticon2-fast-back',
        title: 'First',
        offset: () => 0,
      },
      {
        type: 'prev',
        disabled: pageIndex === 0,
        icon: 'flaticon2-back',
        title: 'Previous',
        offset: () => offset - limit,
      },
    );

    if (pageIndex >= maxPagerItems) {
      items.push({
        type: 'more-prev',
        icon: 'flaticon-more-1',
        title: 'More pages',
        offset: () => (start - 1) * limit,
      });
    }

    // page numbers.
    for (let i = start; i < end; i++) {
      items.push({
        type: 'pageno',
        text: `${i + 1}`,
        title: `${i + 1}`,
        active: pageIndex === i,
        offset: () => i * limit,
      });
    }

    if (pageIndex + maxPagerItems < pageCount) {
      items.push({
        type: 'more-next',
        icon: 'flaticon-more-1',
        title: 'More pages',
        offset: () => end * limit,
      });
    }

    items.push(
      {
        type: 'next',
        disabled: pageIndex === pageCount - 1,
        icon: 'flaticon2-next',
        title: 'Next',
        offset: () => offset + limit,
      },
      {
        type: 'last',
        disabled: pageIndex === pageCount - 1,
        icon: 'flaticon2-fast-next',
        title: 'Last',
        offset: () => (pageCount - 1) * limit,
      },
    );

    return items;
  }
}

/**
 * @file: index.ts
 * @author: eric <xuxiang@zhichetech.com>
 * @copyright: (c) 2019-2020 sichuan zhichetech co., ltd.
 */
import { ListResult, cacheService } from 'lib';
import {
  ActionThunk,
  AsyncListState,
  DispatchFn,
  FetchCallback,
  StandardAction,
} from 'lib/duck/interfaces';
import { autoActionType, createStandardAction } from '../base';
import {
  INVALIDATE_KEYED_LIST,
  KEYED_LIST_CLEAR_SELECTION,
  KEYED_LIST_ITEM_DESELECTED,
  KEYED_LIST_ITEM_SELECTED,
  KEYED_LIST_TOGGLE_ALL_SELECTION,
  LOAD_KEYED_LIST,
  LOAD_KEYED_LIST_FAILED,
  LOAD_KEYED_LIST_FROM_CACHE,
  LOAD_KEYED_LIST_SUCCESS,
  LOAD_MORE_KEYED_LIST,
  LOAD_MORE_KEYED_LIST_FAILED,
  LOAD_MORE_KEYED_LIST_SUCCESS,
  SET_CURRENT_KEYED_LIST_KEY,
  UPDATE_KEYED_LIST_FILTER,
  UPDATE_KEYED_LIST_LIMIT,
  UPDATE_KEYED_LIST_OFFSET,
  UPDATE_KEYED_LIST_TOTAL,
} from '../constants';
import {
  KeyedListActionCreators,
  KeyedListActionCreatorsOptions,
  KeyedListResultAction,
  KeyedListResultActionPayload,
  StandardKeyedListAction,
  StandardKeyedListActionPayload,
} from './interfaces';

export * from './interfaces';

export function createStandardKeyedListAction<TListKey, TExtra = any>(
  type: string,
  rawKey: TListKey,
  getListKeyStringRepresentation: (key: TListKey) => string,
  extra?: TExtra,
): StandardKeyedListAction<TListKey, TExtra> {
  const key = getListKeyStringRepresentation(rawKey);
  return createStandardAction<StandardKeyedListActionPayload<TListKey, TExtra>>(
    type,
    { rawKey, key, extra },
  );
}

export function createKeyedListResultAction<TListKey, T>(
  type: string,
  rawKey: TListKey,
  getListKeyStringRepresentation: (key: TListKey) => string,
  error: Error | null,
  result?: T[] | ListResult<T> | null,
): KeyedListResultAction<TListKey, T> {
  const key = getListKeyStringRepresentation(rawKey);
  return createStandardAction<KeyedListResultActionPayload<TListKey, T>>(type, {
    rawKey,
    key,
    error,
    result,
  });
}

export function createKeyedListActionCreators<
  TAppState,
  TListKey,
  T,
  TFilter = any,
>(
  scope: string,
  options: KeyedListActionCreatorsOptions<TAppState, TListKey, T, TFilter>,
): KeyedListActionCreators<TAppState, TListKey, T, TFilter> {
  if (!options.getListKeyStringRepresentation) {
    throw new Error('missing options.getListKeyStringRepresentation');
  }

  if (!options.fetchHandler) {
    throw new Error('missing options.fetchHandler');
  }

  function loadListByKey(key: TListKey): StandardKeyedListAction<TListKey> {
    return createStandardKeyedListAction(
      autoActionType(scope, LOAD_KEYED_LIST),
      key,
      options.getListKeyStringRepresentation,
    );
  }

  function loadListByKeySuccess(
    key: TListKey,
    result: T[] | ListResult<T>,
  ): KeyedListResultAction<TListKey, T> {
    return createKeyedListResultAction(
      autoActionType(scope, LOAD_KEYED_LIST_SUCCESS),
      key,
      options.getListKeyStringRepresentation,
      null,
      result,
    );
  }

  function loadListByKeyFailed(
    key: TListKey,
    error: Error,
  ): KeyedListResultAction<TListKey, T> {
    return createKeyedListResultAction(
      autoActionType(scope, LOAD_KEYED_LIST_FAILED),
      key,
      options.getListKeyStringRepresentation,
      error,
    );
  }

  function invalidateListByKey(
    key: TListKey,
    reset?: boolean,
    callback?: FetchCallback<T>,
    reload?: boolean,
  ):
    | StandardKeyedListAction<TListKey, boolean | undefined>
    | ActionThunk<TAppState> {
    const invalidateAction = createStandardKeyedListAction(
      autoActionType(scope, INVALIDATE_KEYED_LIST),
      key,
      options.getListKeyStringRepresentation,
      reset,
    );
    if (options.shouldFetchOnInvalidate && reload !== false) {
      return (dispatch: DispatchFn<TAppState>) => {
        dispatch(invalidateAction);
        dispatch(fetchListByKey(key, callback));
      };
    }
    return invalidateAction;
  }

  function fetchListByKey(
    key: TListKey,
    ...args: any[]
  ): ActionThunk<TAppState> {
    return (dispatch: DispatchFn<TAppState>, getState: () => TAppState) => {
      dispatch(loadListByKey(key));

      let callback: FetchCallback<T[] | ListResult<T>> | null = null;
      if (typeof args[args.length - 1] === 'function') {
        callback = args[args.length - 1];
      }

      const state: TAppState = getState();

      let fetchedFromServer = false;
      const loadActionType = autoActionType(scope, LOAD_KEYED_LIST);
      const cacheKey = options.cacheKey || `${loadActionType}.${key}.cached`;

      if (options.cache && options.get) {
        const result = options.get(
          state,
          options.getListKeyStringRepresentation(key),
        );
        if (result === null) {
          cacheService
            .get<T>(cacheKey)
            .then(value => {
              if (!fetchedFromServer && value !== undefined) {
                const loadFromCacheAction = createStandardKeyedListAction(
                  autoActionType(scope, LOAD_KEYED_LIST_FROM_CACHE),
                  key,
                  options.getListKeyStringRepresentation,
                  value,
                );
                dispatch(loadFromCacheAction);
              }
            })
            .catch(() => {
              /* noop */
            });
        }
      }

      const listState = options.getState
        ? options.getState(state, options.getListKeyStringRepresentation(key))
        : null;
      args = [key, listState, state, ...args];
      options.fetchHandler
        .apply(null, args)
        .then((result: any) => {
          fetchedFromServer = true;
          if (
            options.cache &&
            (!options.shouldCacheResult ||
              options.shouldCacheResult(state, key))
          ) {
            // save the result to cache.
            cacheService
              .set(cacheKey, result)
              .then(() => {
                console.log('%s.%s saved to cache', loadActionType, key);
              })
              .catch(() => {
                /* noop */
              });
          }
          callback && callback(null, result);
          dispatch(loadListByKeySuccess(key, result));
        })
        .catch((err: Error) => {
          callback && callback(err, undefined);
          dispatch(loadListByKeyFailed(key, err));
        });
    };
  }

  function loadMoreListByKey(key: TListKey): StandardKeyedListAction<TListKey> {
    return createStandardKeyedListAction(
      autoActionType(scope, LOAD_MORE_KEYED_LIST),
      key,
      options.getListKeyStringRepresentation,
    );
  }

  function loadMoreListByKeySuccess(
    key: TListKey,
    result: T[] | ListResult<T>,
  ): KeyedListResultAction<TListKey, T> {
    return createKeyedListResultAction(
      autoActionType(scope, LOAD_MORE_KEYED_LIST_SUCCESS),
      key,
      options.getListKeyStringRepresentation,
      null,
      result,
    );
  }

  function loadMoreListByKeyFailed(
    key: TListKey,
    error: Error,
  ): KeyedListResultAction<TListKey, T> {
    return createKeyedListResultAction(
      autoActionType(scope, LOAD_MORE_KEYED_LIST_FAILED),
      key,
      options.getListKeyStringRepresentation,
      error,
    );
  }

  function totalUpdatedByKey(
    key: TListKey,
    total: number,
  ): StandardKeyedListAction<TListKey, number> {
    return createStandardKeyedListAction<TListKey, number>(
      autoActionType(scope, UPDATE_KEYED_LIST_TOTAL),
      key,
      options.getListKeyStringRepresentation,
      total,
    );
  }

  function offsetUpdatedByKey(
    key: TListKey,
    offset: number,
  ): StandardKeyedListAction<TListKey, number> {
    return createStandardKeyedListAction<TListKey, number>(
      autoActionType(scope, UPDATE_KEYED_LIST_OFFSET),
      key,
      options.getListKeyStringRepresentation,
      offset,
    );
  }

  function limitUpdatedByKey(
    key: TListKey,
    limit: number,
  ): StandardKeyedListAction<TListKey, number> {
    return createStandardKeyedListAction<TListKey, number>(
      autoActionType(scope, UPDATE_KEYED_LIST_LIMIT),
      key,
      options.getListKeyStringRepresentation,
      limit,
    );
  }

  function itemSelectedByKey(
    key: TListKey,
    item: T,
  ): StandardKeyedListAction<TListKey, T> {
    return createStandardKeyedListAction<TListKey, T>(
      autoActionType(scope, KEYED_LIST_ITEM_SELECTED),
      key,
      options.getListKeyStringRepresentation,
      item,
    );
  }

  function itemDeselectedByKey(
    key: TListKey,
    item: T,
  ): StandardKeyedListAction<TListKey, T> {
    return createStandardKeyedListAction<TListKey, T>(
      autoActionType(scope, KEYED_LIST_ITEM_DESELECTED),
      key,
      options.getListKeyStringRepresentation,
      item,
    );
  }

  function toggleAllSelectionByKey(
    key: TListKey,
  ): StandardKeyedListAction<TListKey, void> {
    return createStandardKeyedListAction<TListKey, undefined>(
      autoActionType(scope, KEYED_LIST_TOGGLE_ALL_SELECTION),
      key,
      options.getListKeyStringRepresentation,
    );
  }

  function clearSelectionByKey(
    key: TListKey,
  ): StandardKeyedListAction<TListKey, void> {
    return createStandardKeyedListAction<TListKey>(
      autoActionType(scope, KEYED_LIST_CLEAR_SELECTION),
      key,
      options.getListKeyStringRepresentation,
    );
  }

  function updateDataOffsetByKey(
    key: TListKey,
    offset: number,
  ): ActionThunk<TAppState> {
    return dispatch => {
      dispatch(offsetUpdatedByKey(key, offset));
      dispatch(fetchListByKey(key));
    };
  }

  function updateDataLimitByKey(
    key: TListKey,
    limit: number,
  ): ActionThunk<TAppState> {
    return dispatch => {
      dispatch(limitUpdatedByKey(key, limit));
      dispatch(fetchListByKey(key));
    };
  }

  function fetchMoreListByKey(
    key: TListKey,
    ...args: any[]
  ): ActionThunk<TAppState> {
    if (!options.getState) {
      throw new Error('options.getState is required for fetchMore action. ');
    }
    return (dispatch: DispatchFn<TAppState>, getState: () => TAppState) => {
      let state: TAppState = getState();

      const mapKey = options.getListKeyStringRepresentation(key);
      let listState = options.getState!(state, mapKey) as AsyncListState<T>;
      if (listState && !listState.hasMore) return;

      dispatch(loadMoreListByKey(key));

      state = getState();
      listState = options.getState!(state, mapKey) as AsyncListState<T>;
      args = [key, listState, state, ...args];

      options.fetchHandler
        .apply(null, args)
        .then((result: T[] | ListResult<T>) => {
          if (Array.isArray(result)) {
            dispatch(loadMoreListByKeySuccess(key, result));
          } else {
            dispatch(totalUpdatedByKey(key, result.total));
            dispatch(loadMoreListByKeySuccess(key, result.items));
          }
        })
        .catch((err: Error) => {
          dispatch(loadMoreListByKeyFailed(key, err));
        });
    };
  }

  function updateListFilterByKey(
    key: TListKey,
    filter: Partial<TFilter>,
  ):
    | ActionThunk<TAppState>
    | StandardKeyedListAction<TListKey, Partial<TFilter>> {
    const predict = options.shouldInvalidateForFilter;
    const shouldInvalidate =
      typeof predict === 'boolean'
        ? predict
        : typeof predict === 'function'
          ? predict(filter, key)
          : false;
    const updateFilterAction = createStandardKeyedListAction(
      autoActionType(scope, UPDATE_KEYED_LIST_FILTER),
      key,
      options.getListKeyStringRepresentation,
      filter,
    );
    if (shouldInvalidate) {
      return (dispatch: DispatchFn<TAppState>) => {
        dispatch(updateFilterAction);
        dispatch(invalidateListByKey(key, false));
      };
    }
    return updateFilterAction;
  }

  function setCurrentKeyedListKey(key: TListKey): StandardAction<TListKey> {
    return createStandardAction(
      autoActionType(scope, SET_CURRENT_KEYED_LIST_KEY),
      key,
    );
  }

  return {
    loadListByKey,
    loadListByKeySuccess,
    loadListByKeyFailed,
    invalidateListByKey,
    fetchListByKey,
    fetchMoreListByKey,
    loadMoreListByKey,
    loadMoreListByKeySuccess,
    loadMoreListByKeyFailed,
    updateListFilterByKey,
    setCurrentKeyedListKey,
    totalUpdatedByKey,
    updateDataOffsetByKey,
    updateDataLimitByKey,
    itemSelectedByKey,
    itemDeselectedByKey,
    toggleAllSelectionByKey,
    clearSelectionByKey,
  };
}

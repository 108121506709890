import classNames from 'classnames';
import React, {
  Component,
  createRef,
  CSSProperties,
  HTMLProps,
  MouseEvent,
  ReactElement,
  ReactNode,
} from 'react';
import { SvgrComponent } from 'src/declarations';
import { addTransitionEndEventListener } from 'utils/dom';
import { ResponsiveSizeType, ResponsiveSizeTypeMap } from '../types';
import { Button, ButtonProps } from './Button';

export type DropdownChildrenRenderType =
  | 'button-contents'
  | 'dropdown-menu-contents';

export interface DropdownChildrenObject {
  buttonContents?: ReactNode;
  dropdownMenuContents?: ReactNode;
}

type DropdownChildrenObjectFunction = () => DropdownChildrenObject;
type DropdownContentsFunction = (type: DropdownChildrenRenderType) => ReactNode;

type DropdownChildren =
  | ReactNode
  | DropdownContentsFunction
  | DropdownChildrenObjectFunction;

export type DropdownPlacement =
  | 'bottom-start'
  | 'bottom-center'
  | 'bottom-end'
  | 'top-start'
  | 'top-center'
  | 'top-end'
  | 'left-start'
  | 'left-center'
  | 'left-end'
  | 'right-start'
  | 'right-center'
  | 'right-end';

export interface DropdownMenuProps
  extends Omit<HTMLProps<HTMLDivElement>, 'size'> {
  fit?: boolean;
  offset?: { left?: number; top?: number };
  animated?: boolean;
  topSquared?: boolean;
  size?: ResponsiveSizeType;
  dismissOnClick?: boolean;
  placement?: DropdownPlacement;
  width?: number | string | 'match-button';
}

export interface DropdownButtonProps
  extends Omit<ButtonProps, 'ref' | 'children'> {
  split?: boolean;
  renderCustomButton?: (children?: ReactNode) => ReactElement;
  inline?: boolean;
  buttonClassName?: string;
  buttonContents?: ReactNode;
  dropdownContents?: ReactNode;
  dropdownProps?: DropdownMenuProps;
  children?: DropdownChildren;
  items?: Array<DropdownItem | '-'>;
  showToggleButton?: boolean;
  elStyle?: CSSProperties;
  onItemClick?: (item: DropdownItem) => void;
}

interface State {
  show: boolean;
}

export interface DropdownItem {
  name: string;
  text: string;
  icon?: string | SvgrComponent;
  url?: string;
}

// eslint-disable-next-line @typescript-eslint/ban-types
function isFunction(f: any): f is Function {
  return typeof f === 'function';
}

function isDropdownChildrenObjectFunction(
  f: any,
): f is DropdownChildrenObjectFunction {
  return isFunction(f) && f.length === 0;
}

export class Dropdown extends Component<DropdownButtonProps, State> {
  static defaultProps: Partial<DropdownButtonProps> = {
    showToggleButton: true,
  };

  private readonly buttonRef = createRef<HTMLElement>();
  private readonly buttonSplitRef = createRef<HTMLElement>();
  private readonly ref = createRef<HTMLDivElement>();
  private readonly dropdownRef = createRef<HTMLDivElement>();

  constructor(props: DropdownButtonProps) {
    super(props);
    this.state = { show: false };
  }

  render() {
    const {
      tag,
      type,
      className,
      color,
      outline,
      outlineHover,
      label,
      bold,
      clean,
      uppercased,
      size,
      inline,
      fontSize,
      wide,
      block,
      link,
      tall,
      pill,
      square,
      air,
      iconOnly,
      elevated,
      style,
      elStyle,
      showToggleButton,
      spinner,
      spinnerPos,
      spinnerSize,
      file,
      accepts,
      onFileChange,
      circle,
      buttonClassName,
      buttonContents,
      dropdownContents,
      children,
      split,
      dropdownProps,
      onClick,
      renderCustomButton,
      ...props
    } = this.props;

    const buttonProps: Omit<ButtonProps, 'ref'> = {
      tag,
      type,
      className: buttonClassName,
      color,
      outline,
      outlineHover,
      label,
      bold,
      clean,
      uppercased,
      size,
      fontSize,
      wide,
      block,
      link,
      tall,
      pill,
      square,
      air,
      iconOnly,
      elevated,
      style,
      spinner,
      spinnerPos,
      spinnerSize,
      file,
      accepts,
      onFileChange,
      circle,
    };

    const childrenObject = this.getChildren(
      buttonContents,
      dropdownContents,
      children,
    );

    let customButton = renderCustomButton?.(childrenObject.buttonContents);
    if (customButton) {
      customButton = React.cloneElement(customButton, {
        ...customButton.props,
        onClick: this.onButtonClick,
      });
    }

    const {
      size: dropdownSize,
      fit,
      placement,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      offset,
      animated,
      topSquared,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      dismissOnClick,
      ...otherProps
    } = dropdownProps || {};

    const dropdownSz = dropdownSize && ResponsiveSizeTypeMap.get(dropdownSize);

    return (
      <div
        ref={this.ref}
        style={elStyle}
        className={classNames(className, {
          dropdown:
            !split &&
            (!dropdownProps || !dropdownProps.placement?.startsWith('top')),
          'dropdown-inline': inline,
          dropup:
            !split &&
            dropdownProps &&
            dropdownProps.placement?.startsWith('top'),
          'btn-group': split,
        })}
        {...props}
      >
        {customButton || (
          <Button
            {...buttonProps}
            forwardedRef={this.buttonRef}
            onClick={split ? onClick : this.onButtonClick}
            className={classNames({
              'dropdown-toggle': !split && showToggleButton,
            })}
            aria-haspopup="true"
            aria-expanded="true"
          >
            {childrenObject.buttonContents}
          </Button>
        )}
        {split && (
          <Button
            {...buttonProps}
            forwardedRef={this.buttonSplitRef}
            className={classNames({
              'dropdown-toggle': true,
              'dropdown-toggle-split': true,
            })}
            aria-haspopup="true"
            aria-expanded="false"
            onClick={this.onButtonSplitClick}
          >
            <span className="sr-only" />
          </Button>
        )}
        <div
          ref={this.dropdownRef}
          className={classNames('dropdown-menu', {
            'dropdown-menu-right':
              placement === 'bottom-end' || placement === 'top-end',
            'dropdown-menu-anim': animated,
            'dropdown-menu-top-unround': topSquared,
            'dropdown-menu-fit': fit,
            [`dropdown-menu-${dropdownSz}`]: dropdownSz,
          })}
          {...otherProps}
        >
          {childrenObject.dropdownMenuContents}
        </div>
      </div>
    );
  }

  onButtonClick = (e: MouseEvent<HTMLElement>) => {
    e.preventDefault();
    this.props.onClick && this.props.onClick(e);
    this.show(e);
  };

  onButtonSplitClick = (e: MouseEvent<HTMLElement>) => {
    e.preventDefault();
    this.show(e);
  };

  onDocumentClick = (e: any) => {
    const { dropdownProps } = this.props;

    const dropdownEl = this.dropdownRef.current;
    if (!dropdownEl || !this.ref.current) return;

    const [x, y] = [e.clientX, e.clientY];
    const rect = dropdownEl.getBoundingClientRect();

    if (
      x >= rect.left &&
      x <= rect.right &&
      y >= rect.top &&
      y <= rect.bottom
    ) {
      if (dropdownProps && dropdownProps.dismissOnClick === false) {
        return;
      }
    }

    this.hide();
  };

  private show(e: MouseEvent<HTMLElement>) {
    e.preventDefault();
    const { dropdownProps } = this.props;

    const dropdownEl = this.dropdownRef.current;
    if (!dropdownEl || !this.ref.current) return;

    let left = 0;
    let top = 0;

    if (dropdownProps?.animated) {
      dropdownEl.classList.remove('dropdown-menu-anim');
    }

    if (!dropdownEl.classList.contains('show')) {
      dropdownEl.style.left = 'auto';
      dropdownEl.style.visibility = 'hidden';
      dropdownEl.style.display = 'block';
    }

    if (dropdownProps?.width) {
      if (dropdownProps.width === 'match-button') {
        dropdownEl.style.width = this.ref.current!.offsetWidth + 'px';
      } else {
        dropdownEl.style.width =
          typeof dropdownProps.width === 'number'
            ? `${dropdownProps.width}px`
            : dropdownProps.width;
      }
      dropdownEl.style.minWidth = '0px';
    }

    const dstyle = getComputedStyle(dropdownEl);
    const [dmt, dmr, dmb, dml] = [
      parseFloat(dstyle.marginTop) || 0,
      parseFloat(dstyle.marginRight) || 0,
      parseFloat(dstyle.marginBottom) || 0,
      parseFloat(dstyle.marginLeft) || 0,
    ];
    const dw = dropdownEl.offsetWidth;
    const dh = dropdownEl.offsetHeight;

    if (dropdownProps?.animated) {
      dropdownEl.classList.add('dropdown-menu-anim');
    }

    const [vw, vh] = [
      Math.max(document.documentElement.clientWidth, window.innerWidth || 0),
      Math.max(document.documentElement.clientHeight, window.innerHeight || 0),
    ];

    if (!dropdownEl.classList.contains('show')) {
      dropdownEl.style.display = 'none';
      dropdownEl.style.visibility = 'visible';
    }

    const [placement, align] = (
      dropdownProps?.placement || 'bottom-start'
    ).split('-');

    if (placement === 'bottom' || placement === 'top') {
      const el = e.currentTarget as HTMLElement;
      const style = getComputedStyle(el);
      const marginTop = parseFloat(style.marginTop) || 0;
      const marginBottom = parseFloat(style.marginBottom) || 0;
      const rect = el.getBoundingClientRect();
      const width = el.offsetWidth;
      const height = el.offsetHeight;

      if (align === 'end') {
        left = width - dw;
      } else if (align === 'center') {
        left = (width - dw) / 2;
      } else {
        // start
        left = 0;
      }

      if (placement === 'bottom') {
        top = height + marginBottom + dmt + dmb;

        // keep the dropdown inside the viewport
        const bottom = rect.bottom + marginBottom + dh + dmt + dmb;
        if (bottom > vh && rect.top - marginTop - dmt - dmb - dh >= 0) {
          top = -(dh + marginTop + dmb + dmt);
        }
      } else {
        top = -(dh + dmt + dmb + marginTop);

        // keep the dropdown inside the viewport
        if (
          rect.top - dh - dmb - dmt - marginTop < 0 &&
          rect.bottom + dh + marginBottom + dmt + dmb <= vh
        ) {
          top = height + marginBottom + dmt + dmb;
        }
      }
    } else if (placement === 'left' || placement === 'right') {
      const el = this.ref.current;
      const style = getComputedStyle(el);
      const marginLeft = parseFloat(style.marginLeft) || 0;
      const marginRight = parseFloat(style.marginRight) || 0;
      const rect = el.getBoundingClientRect();
      const width = el.offsetWidth;
      const height = el.offsetHeight;

      if (align === 'end') {
        top = height - dh;
      } else if (align === 'center') {
        top = (height - dh) / 2;
      } else {
        // start
        top = 0;
      }

      if (placement === 'left') {
        left = -(dw + dmr + dml + marginLeft);

        // keep the dropdown inside the viewport
        if (
          rect.left - (dw + dmr + dml + marginLeft) < 0 &&
          rect.right + dw + marginRight + dml + dmr <= vw
        ) {
          left = width + marginRight + dml + dmr;
        }
      } else {
        left = width + marginRight + dml + dmr;

        // keep the dropdown inside the viewport
        if (
          rect.right + dw + marginRight + dml + dmr > vw &&
          rect.left - (dw + marginLeft + dmr + dml) >= 0
        ) {
          left = -(dw + marginLeft + dmr + dml);
        }
      }
    }

    dropdownEl.style.left = `${left}px`;
    dropdownEl.style.top = `${top}px`;
    dropdownEl.style.right = '';
    dropdownEl.style.bottom = '';

    if (!dropdownEl.classList.contains('show')) {
      setTimeout(() => {
        document.body.addEventListener('click', this.onDocumentClick);
      }, 0);
    }

    dropdownEl.style.display = '';
    dropdownEl.style.opacity = '0';
    dropdownEl.classList.add('show');
    this.ref.current!.classList.add('show');
    setTimeout(() => {
      dropdownEl.style.opacity = '1';
    });
  }

  // eslint-disable-next-line @typescript-eslint/member-ordering
  hide() {
    document.body.removeEventListener('click', this.onDocumentClick);

    const dropdownEl = this.dropdownRef.current;
    if (!dropdownEl || !this.ref.current) return;

    if (!dropdownEl.classList.contains('show')) {
      return;
    }

    dropdownEl.style.opacity = '0';
    addTransitionEndEventListener(dropdownEl, () => {
      dropdownEl.classList.remove('show');
      this.ref.current?.classList.remove('show');
    });
  }

  private getChildren(
    buttonContents?: ReactNode,
    dropdownMenuContents?: ReactNode,
    children?: DropdownChildren,
  ): DropdownChildrenObject {
    if (!buttonContents) {
      if (isFunction(children)) {
        if (isDropdownChildrenObjectFunction(children)) {
          const results = children();
          buttonContents = results.buttonContents;
        } else {
          buttonContents = children('button-contents');
        }
      }
    }

    if (!dropdownMenuContents) {
      if (this.props.items) {
        const onItemClick = (item: DropdownItem) => {
          return (e: MouseEvent<HTMLElement>) => {
            e.preventDefault();
            this.props.onItemClick && this.props.onItemClick(item);
          };
        };
        dropdownMenuContents = this.props.items.map((item, index) => {
          if (item === '-') {
            return (
              <div className="dropdown-divider" key={`separator-${index}`} />
            );
          }
          return (
            <a
              href={item.url || '#'}
              key={item.name}
              onClick={onItemClick(item)}
            >
              {item.icon && (
                <>
                  {typeof item.icon === 'string' ? (
                    <i className={item.icon} />
                  ) : (
                    <item.icon />
                  )}{' '}
                </>
              )}
              {item.text}
            </a>
          );
        });
      } else {
        if (isFunction(children)) {
          if (isDropdownChildrenObjectFunction(children)) {
            const results = children();
            dropdownMenuContents = results.dropdownMenuContents;
          } else {
            dropdownMenuContents = children('dropdown-menu-contents');
          }
        } else {
          dropdownMenuContents = children;
        }
      }
    }

    return { buttonContents, dropdownMenuContents };
  }
}

import classNames from 'classnames';
import React, { HTMLProps } from 'react';
import { SvgrComponent } from 'src/declarations';
import { ColorType, ResponsiveSizeType, ResponsiveSizeTypeMap } from '../types';

export interface Props extends Omit<HTMLProps<HTMLElement>, 'color' | 'size'> {
  icon: SvgrComponent;
  size: ResponsiveSizeType;
  color: ColorType;
}

export const SvgIcon = React.memo<Props>(({ icon, size, color }) => {
  const sz = size && ResponsiveSizeTypeMap.get(size);
  const Svg = icon;
  return (
    <Svg
      className={classNames('kt-svg-icon', {
        [`kt-svg-icon--${sz}`]: sz,
        [`kt-svg-icon--${color}`]: color,
      })}
    />
  );
});

import classNames from 'classnames';
import React, { PureComponent, MouseEvent, CSSProperties } from 'react';
import { Menu, MenuItem } from './types';
import { getString } from 'shared/components';
import { addTransitionEndEventListener } from 'utils/dom';
import { NavLink, Route } from 'react-router-dom';

import './menu.scss';

interface Props {
  menu: Menu;
  isMainNav?: boolean;
  className?: string;
  style?: CSSProperties;
  onMenuItemClick?: (item: MenuItem) => void;
}

export class MenuNav extends PureComponent<Props> {
  private activeDropdownMenuItemEl: HTMLElement | null = null;

  render() {
    const { menu, style, className } = this.props;
    return (
      <ul className={classNames('kt-menu__nav', className)} style={style}>
        {menu.sections.map((section, index) => (
          <React.Fragment key={section.key || index}>
            {section.title && (
              <li className="kt-menu__section">
                <h4 className="kt-menu__section-text">
                  {getString(section.title)}
                </h4>
                <i className="kt-menu__section-icon flaticon-more-v2" />
              </li>
            )}
            {section.items.map(item => this.renderMenuItem(item))}
          </React.Fragment>
        ))}
      </ul>
    );
  }

  renderMenuItem(item: MenuItem) {
    const hasSubMenu = Boolean(item.items && item.items.length > 0);
    return (
      <Route path={item.url} exact={true} key={item.id}>
        {({ match }) => (
          <li
            aria-haspopup={hasSubMenu ? 'true' : 'false'}
            data-item={item.id}
            id={item.id}
            onClick={this.onMenuItemClick}
            style={item.style}
            className={classNames('kt-menu__item', item.className, {
              'kt-menu__item--active':
                item.active || (match && this.props.isMainNav),
              'kt-menu__item--rel': item.positionRelative,
              'kt-menu__item--hover': item.hover,
              'kt-menu__item--submenu': hasSubMenu,
              'kt-menu__item--open-dropdown':
                (hasSubMenu && item.dropdown) || item.forceDropdownClass,
            })}
          >
            <NavLink
              to={item.url || '#'}
              className={classNames('kt-menu__link', {
                'kt-menu__toggle': hasSubMenu,
              })}
            >
              {item.icon && (
                <span className="kt-menu__link-icon">
                  {typeof item.icon === 'string' ? (
                    <i className={item.icon} />
                  ) : (
                    <item.icon />
                  )}
                </span>
              )}
              {!item.icon && !item.noBullet && (
                <i className="kt-menu__link-bullet kt-menu__link-bullet--dot">
                  <span />
                </i>
              )}
              <span className="kt-menu__link-text">
                {typeof item.text === 'string'
                  ? getString(item.text)
                  : item.text}
              </span>
              {hasSubMenu && (
                <i className="kt-menu__ver-arrow la la-angle-right" />
              )}
            </NavLink>
            {hasSubMenu && (
              <div
                className={classNames('kt-menu__submenu', {
                  'kt-menu__submenu--fixed':
                    item.dropdown &&
                    item.dropdownProps &&
                    item.dropdownProps.fixed,
                  'kt-menu__submenu--classic':
                    item.dropdown &&
                    item.dropdownProps &&
                    item.dropdownProps.classic,
                  'kt-menu__submenu--left':
                    item.dropdown &&
                    item.dropdownProps &&
                    item.dropdownProps.placement === 'left',
                  'kt-menu__submenu--right':
                    item.dropdown &&
                    item.dropdownProps &&
                    item.dropdownProps.placement === 'right',
                })}
              >
                <span className="kt-menu__arrow" />
                <ul className="kt-menu__subnav">
                  {item.items!.map(subItem => this.renderMenuItem(subItem))}
                </ul>
              </div>
            )}
          </li>
        )}
      </Route>
    );
  }

  getMenuItemById(id: string) {
    const findMenuById = (item: MenuItem): MenuItem | null => {
      if (item.id === id) return item;
      if (item.items) {
        for (const subItem of item.items) {
          const found = findMenuById(subItem);
          if (found) return found;
        }
      }
      return null;
    };

    for (const section of this.props.menu.sections) {
      for (const item of section.items) {
        const found = findMenuById(item);
        if (found) return found;
      }
    }

    return null;
  }

  onMenuItemClick = (e: MouseEvent<HTMLElement>) => {
    e.stopPropagation();

    const el = e.currentTarget as HTMLElement;
    const id = el.getAttribute('data-item');

    if (!id) return;

    const item = this.getMenuItemById(id);

    if (!item) return;

    if (!item.url || item.url === '#') {
      e.preventDefault();
      item.handler && item.handler(item.context);
      this.props.onMenuItemClick && this.props.onMenuItemClick(item);
    }

    if (!item.items?.length) {
      return;
    }

    const [x, y] = [e.clientX, e.clientY];
    const rect = el.getBoundingClientRect();
    if (
      x >= rect.left &&
      x <= rect.right &&
      y >= rect.top &&
      y <= rect.bottom
    ) {
      setTimeout(() => {
        if (item.dropdown) {
          if (!this.activeDropdownMenuItemEl) {
            this.handleMenuDropdown(el);
          }
        } else {
          this.handleMenuSlide(el);
        }
      }, 0);
    }
  };

  private readonly onDocumentClick = () => {
    this.hideSubmenu();
  };

  private showSubmenu(el: HTMLElement) {
    el.classList.add('kt-menu__item--hover');
    document.body.addEventListener('click', this.onDocumentClick);
    this.activeDropdownMenuItemEl = el;
  }

  private hideSubmenu() {
    const el = this.activeDropdownMenuItemEl!;
    el.classList.remove('kt-menu__item--hover');
    document.body.removeEventListener('click', this.onDocumentClick);
    this.activeDropdownMenuItemEl = null;
  }

  private handleMenuDropdown(el: HTMLElement) {
    if (this.activeDropdownMenuItemEl) {
      this.hideSubmenu();
    } else {
      this.showSubmenu(el);
    }
  }

  private handleMenuSlide(el: HTMLElement) {
    const getSubmenuHeight = () => {
      const attr = elSubmenu.getAttribute('data-height');
      let height = attr ? Number(attr) : 0;

      if (!height) {
        // calculate the height of the submenu.
        const css = elSubmenu.style.cssText;
        elSubmenu.style.cssText =
          'position: absolute; visibility: hidden; display: block;';

        height = elSubmenu.offsetHeight;

        elSubmenu.style.cssText = css;

        elSubmenu.setAttribute('data-height', `${height}`);
      }

      return height;
    };

    // expand or collpase the item
    const elSubmenu = el.querySelector('.kt-menu__submenu') as HTMLElement;
    if (!elSubmenu) return;

    if (el.classList.contains('kt-menu__item--open')) {
      // collapse
      const height = getSubmenuHeight();

      addTransitionEndEventListener(elSubmenu, () => {
        el.classList.remove('kt-menu__item--open');
        el.classList.remove('kt-menu__item--open-leave');
        elSubmenu.style.maxHeight = '';
        elSubmenu.style.overflow = '';
      });

      elSubmenu.style.maxHeight = `${height}px`;

      const cancelToken = requestAnimationFrame(() => {
        cancelAnimationFrame(cancelToken);
        el.classList.add('kt-menu__item--open-leave');
        elSubmenu.style.overflow = 'hidden';
        elSubmenu.style.maxHeight = '0px';
      });
    } else {
      // expand
      const height = getSubmenuHeight();

      elSubmenu.style.overflow = 'hidden';
      elSubmenu.style.maxHeight = '0px';
      elSubmenu.style.display = 'block';
      el.classList.add('kt-menu__item--open-enter');

      addTransitionEndEventListener(elSubmenu, () => {
        el.classList.add('kt-menu__item--open');
        el.classList.remove('kt-menu__item--open-enter');
        elSubmenu.style.overflow = '';
        elSubmenu.style.maxHeight = '';
        elSubmenu.style.display = '';
      });

      const cancelToken = requestAnimationFrame(() => {
        cancelAnimationFrame(cancelToken);
        elSubmenu.style.maxHeight = `${height}px`;
      });
      // setTimeout(() => {
      //   elSubmenu.style.maxHeight = `${height}px`;
      // }, 10);
    }
  }
}

import classNames from 'classnames';
import { cacheService } from 'lib';
import { HTMLProps, PureComponent } from 'react';

interface Props extends HTMLProps<HTMLSpanElement> {
  src: string;
}

interface State {
  loading: boolean;
  svg: string | null;
}

export class InlineSvg extends PureComponent<Props, State> {
  state: State = { svg: null, loading: false };
  disposed = false;

  componentDidMount() {
    if (this.props.src) {
      void this.loadSvg();
    }
  }

  componentWillUnmount() {
    this.disposed = true;
  }

  componentDidUpdate(prevProps: Props) {
    if (this.props.src !== prevProps.src) {
      if (this.props.src) {
        void this.loadSvg();
      } else {
        this.setState({ svg: null });
      }
    }
  }

  render() {
    const { svg, loading } = this.state;
    const { src, className, ...props } = this.props;
    if (loading) {
      return <i className="fa fa-spinner fa-pulse" style={{ color: '#ddd' }} />;
    }
    if (!svg || !src) return <span />;
    return (
      <span
        className={classNames('svg--inline', className)}
        dangerouslySetInnerHTML={{ __html: svg }}
        {...props}
      />
    );
  }

  async loadSvg() {
    const src = this.props.src;
    if (!src) return;

    const cached = await cacheService.get<string>(src);
    if (cached) {
      this.setState({ svg: cached });
      return;
    }

    this.setState({ loading: true });
    fetch(src)
      .then(res => {
        if (res.ok) {
          return res.text();
        }
        return new Promise((_resolve, reject) => {
          res
            .text()
            .then(text => {
              reject(new Error(text));
            })
            .catch(_error => {
              const error = new Error('error fetch svg document: ' + src);
              reject(error);
            });
        });
      })
      .then(async (svg: string) => {
        const index = svg.indexOf('<svg');

        if (index < 0) {
          console.error('error load svg document: %s', svg);
          if (!this.disposed) {
            this.setState({ svg: null, loading: false });
          }
          return;
        }

        try {
          // ensure the response is a valid SVG xml document.
          const parser = new DOMParser();
          parser.parseFromString(svg, 'text/xml');
        } catch (e) {
          console.error('invalid svg document: ', e);
          if (!this.disposed) {
            this.setState({ svg: null, loading: false });
          }
          return;
        }

        // remove svg xml file prolog including <?xml ...><!DOCTYPE... >
        if (index > 0) {
          svg = svg.substr(index);
        }

        try {
          await cacheService.set(src, svg);
        } catch (e) {
          console.warn('failed to cache inline svg %s', src);
        }

        if (!this.disposed) {
          this.setState({ svg, loading: false });
        }
      })
      .catch(err => {
        console.error(err);
        this.setState({ svg: null, loading: false });
      });
  }
}

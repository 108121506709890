import React from 'react';
import { Button, ButtonProps } from './Button';
import { SvgIcon } from './SvgIcon';

// @ts-ignore
import AddIcon from '!@svgr/webpack!lib/metronic/assets/icons/svg/files/file-plus.svg';

export const AddButton = React.memo<ButtonProps>(
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  ({ children, ref, ...props }) => (
    <Button iconOnly {...props}>
      <SvgIcon icon={AddIcon} size="medium" color="success" />
      {children}
    </Button>
  ),
);
